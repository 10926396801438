import $ from 'jquery';
import DataTable from 'datatables.net-bs';

$(function(){
  const TableOptions = window.TableOptions;
  const zevOrgSummaryTable = $("#zev_org_summary_table")

  var defaultContent = 'N/A';
  var columns = [
    {
      name: 'node_name',
      orderable: true,
      className: 'heavy-border-right',
      data: function (row) {
        var name;
        if (row.node_alt_name === null || row.node_alt_name === '') {
          name = row.node_name;
        } else {
          name = row.node_alt_name;
        }

        return `<a href="/FleetDASH/nodes/${row.node_id}?organization_id=${TableOptions.organizationId}&hierarchy_id=${TableOptions.hierarchyId}&tab=zev">${name}</a>`;
      }
    },
    {
      name: 'total_vehicle_count',
      data: 'total_vehicle_count',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'current_zev_count',
      data: 'current_zev_count',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'zev_candidate_great_count',
      data: 'zev_candidate_great_count',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'zev_candidate_good_count',
      data: 'zev_candidate_good_count',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'zev_candidate_mediocre_count',
      data: 'zev_candidate_mediocre_count',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'zev_candidate_challenging_count',
      data: 'zev_candidate_challenging_count',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'zev_candidate_no_option_count',
      data: 'zev_candidate_no_option_count',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
  ];
  var columnIndexByName = columns.reduce(function(byName, col, index) { byName[col.name] = index; return byName }, {});
  var vehicleClass;
  var dataTable;

  // default to showing the light duty data
  initDataTable("LD")

  // Initializes and retrieves the dataTables
  function initDataTable(vehicleClass) {
    dataTable = new DataTable(zevOrgSummaryTable, {
      serverSide: false,
      ordering: true,
      order: [[columnIndexByName.node_name, 'desc']],
      retrieve: true,
      ajax: function (data, callback) {
        $.ajax({
          url: TableOptions.zevOrgChildNodeSummaryUrl,
          data: {
            fiscal_year: TableOptions.vatFiscalYear,
            vehicle_class: vehicleClass,
            combine: "y",
          },
          success: function (json) {
            var jsonData = [];
            if (json.result) {
              for (var i = 0; i < json.result.length; i++) {
                jsonData.push(json.result[i]);
              }
            }

            var o = {
              recordsTotal: json.metadata.resultset.count,
              recordsFiltered: json.metadata.resultset.count,
              data: jsonData
            };
            callback(o);
          }
        });
      },
      columns: columns,
    });
  };

  // Summary table selection logic
  $(document).on('change', '.select-zev-table', function() {
    vehicleClass = $(this).val();

    // Redraw data for table and set the table's vehicle name
    dataTable.destroy();
    switch(vehicleClass) {
      case "LD":
        $("#vehicle-class-header").text("Light Duty Vehicles");
        break;
      case "HD":
        $("#vehicle-class-header").text("Medium and Heavy Duty Vehicles");
        break;
      case "All":
        $("#vehicle-class-header").text("All Vehicles");
        vehicleClass = null;
        break;
    };
    initDataTable(vehicleClass)
  });
});
