import $ from 'jquery';
import DataTable from 'datatables.net-bs';
import moment from 'moment';

$(function () {
  const TableOptions = window.TableOptions;
  const formatUrlDate = window.formatUrlDate;
  const tablePercentage = window.tablePercentage;

  // can be changed by radio on vehicle tables
  var startDate = TableOptions.fp_start_date;
  var endDate = TableOptions.fp_end_date;

  $("#vehicles .search-month").val("FYTD");

  // specific to the vehicle table
  const vehiclesDataTable = new DataTable('#vehicles_table', {
    serverSide: true,
    ordering: true,
    order: [[7, 'desc']],
    'columnDefs': [
      {className: 'justify-right', targets: [1, 2, 3, 4, 5, 6, 7, 8]}
    ],
    'ajax': function (data, callback) {
      let sort_by = 'avoidable_ghgs';
      switch (data.order[0].column) {
        case 0:
          sort_by = 'vehicle_internal_id';
          break;
        case 1:
          sort_by = 'vehicle_fuel_type';
          break;
        case 4:
          sort_by = 'alt_gges';
          break;
        case 7:
          sort_by = 'non_alt_missed_opp_gges';
          break;
        default:
          sort_by = 'non_alt_missed_opp_gges';
          break;
      }

      $.ajax({
        url: TableOptions.vehiclesUrl,
        data: {
          limit: data.length,
          page: data.start/10 + 1,
          fp_start_date: formatUrlDate(startDate),
          fp_end_date: formatUrlDate(endDate),
          sort_order: data.order[0].dir,
          sort_by: sort_by
        },
        success: function (json) {
          var jsonData = [];
          if (json.result) {
            for (var i = 0; i < json.result.length; i++) {
              jsonData.push(json.result[i]);
            }
          }

          var o = {
            recordsTotal: json.metadata.resultset.count,
            recordsFiltered: json.metadata.resultset.count,
            data: jsonData
          };
          callback(o);
        }
      });
    },
    'columns': [
      {'data': function (row) { // column 0
          var nodeId = typeof TableOptions.nodeId === 'undefined' ? '' : TableOptions.nodeId;

          var treeString = '';
          if (TableOptions.nodeId) {
            if (typeof TableOptions.nodeTree !== 'undefined') {
              TableOptions.nodeTree.forEach(function (node) {
                treeString += '&node_tree[]=' + node;
              });
              treeString += '&node_tree[]=' + TableOptions.nodeId;
            } else {
              treeString = '&node_tree[]=' + TableOptions.nodeId;
            }
          }

          return '<a href="/FleetDASH/vehicles/' + row.vehicle_id + '?organization_id=' + TableOptions.organizationId + '&hierarchy_id=' + TableOptions.hierarchyId + '&node_id=' + nodeId + treeString + '">' + row.vehicle_internal_id + '</a>';
        },
        'orderable': true
      },
      {
        'data': 'vehicle_fuel_type', // column 1
        'orderable': true
      },
      {
        'data': function (row) { // column 2
          return Math.round(row.non_alt_missed_opp_gges + row.non_alt_no_missed_opp_gges).toLocaleString();
        },
        'orderable': false
      },
      {
        'data': function (row) { // column 3
          return tablePercentage(row, row.non_alt_no_missed_opp_gges + row.non_alt_missed_opp_gges);
        },
        'orderable': false
      },
      {
        'data': function (row) { // column 4
          return Math.round(row.alt_gges).toLocaleString();
        },
        'orderable': true
      },
      {
        'data': function (row) { // column 5
          return tablePercentage(row, row.alt_gges);
        },
        'orderable': false
      },
      {
        'data': function (row) { // column 6
          return Math.round(row.non_alt_no_missed_opp_gges + row.alt_gges + row.non_alt_missed_opp_gges).toLocaleString();
        },
        'orderable': false
      },
      {
        'data': function (row) { // column 7
          return Math.round(row.non_alt_missed_opp_gges).toLocaleString();
        },
        // 'orderSequence': ['desc', 'asc']
        'orderable': true
      },
      {
        'data': function (row) { // column 8
          return tablePercentage(row, row.non_alt_missed_opp_gges);
        },
        'orderable': false
      }
    ]
  });

  const vehicleDataTable = new DataTable('#vehicle_table', {
    serverSide: true,
    ordering: true,
    order: [6, 'desc'],
    'columnDefs': [
      {className: 'justify-right', targets: [1, 2, 3, 4, 5, 6, 7]}
    ],
    'ajax': function (data, callback) {
      $.ajax({
        url: TableOptions.vehiclesUrl,
        data: {
          limit: data.length,
          page: data.start/10 + 1,
          fp_start_date: formatUrlDate(startDate),
          fp_end_date: formatUrlDate(endDate),
          sort_order: data.order[0].dir
        },
        success: function (json) {
          var jsonData = [];
          if (json.result) {
            for (var i = 0; i < json.result.length; i++) {
              jsonData.push(json.result[i]);
            }
          }
          var o = {
            recordsTotal: json.metadata.resultset.count,
            recordsFiltered: json.metadata.resultset.count,
            data: jsonData
          };
          callback(o);
        }
      });
    },
    'columns': [
      {
        'data': 'vehicle_fuel_type', // column 0
        'orderable': false
      },
      {
        'data': function (row) { // column 1
          return Math.round(row.non_alt_missed_opp_gges + row.non_alt_no_missed_opp_gges).toLocaleString();
        },
        'orderable': false
      },
      {
        'data': function (row) { // column 2
          return tablePercentage(row, row.non_alt_no_missed_opp_gges + row.non_alt_missed_opp_gges);
        },
        'orderable': false
      },
      {
        'data': function (row) { // column 3
          return Math.round(row.alt_gges).toLocaleString();
        },
        'orderable': false
      },
      {
        'data': function (row) { // column 4
          return tablePercentage(row, row.alt_gges);
        },
        'orderable': false
      },
      {
        'data': function (row) { // column 5
          return Math.round(row.non_alt_no_missed_opp_gges + row.alt_gges + row.non_alt_missed_opp_gges).toLocaleString();
        },
        'orderable': false
      },
      {
        'data': function (row) { // column 6
          return Math.round(row.non_alt_missed_opp_gges).toLocaleString();
        },
        // 'orderSequence': ['desc', 'asc']
        'orderable': true
      },
      {
        'data': function (row) { // column 7
          return tablePercentage(row, row.non_alt_missed_opp_gges);
        },
        'orderable': false
      }
    ]
  });

  var fytdStartDate = TableOptions.fp_start_date;
  var fytdEndDate = TableOptions.fp_end_date;

  $(document).on('change', '#vehicles .search-month, #vehicleIndividualTable .search-month', function () {
    setFpCsvLink();

    var selectedMonth = $(this).val();

    var startDay;
    var endDay;

    if ($(this).val() === 'FYTD') {
      startDay = moment(fytdStartDate, 'MM/DD/YYYY').toDate();
      const latestTxnMonth = moment(TableOptions.latestTxnData.split("/")[0] + "/01/" + TableOptions.latestTxnData.split("/")[1], 'MM/DD/YYYY').toDate();
      endDay = new Date(latestTxnMonth.getFullYear(), latestTxnMonth.getMonth() + 1, 0);
      startDate = formatUrlDate(fytdStartDate);
      endDate = formatUrlDate(fytdEndDate);
    } else {
      var theMonth = selectedMonth.split(" ")[0];
      var theYear = selectedMonth.split(" ")[1];

      var monthNum = moment(theMonth + " 1, " + theYear, 'MMM DD, YYYY').toDate().getMonth();
      startDay = new Date(theYear, monthNum, 1);
      endDay = new Date(theYear, monthNum + 1, 0);
      startDate = formatUrlDate(startDay.getMonth() + 1 + "/" + startDay.getDate() + "/" + startDay.getFullYear());
      endDate = formatUrlDate(endDay.getMonth() + 1 + "/" + endDay.getDate() + "/" + endDay.getFullYear());
    }

    if ($('#vehicles_table').length) {
      var vehicleSummaryUrl = TableOptions.vehiclesUrl + ".csv?fp_start_date=" + encodeURIComponent(formatUrlDate((startDay.getMonth() + 1) + "/" + startDay.getDate() + "/" + startDay.getFullYear())) + "&fp_end_date=" + encodeURIComponent(formatUrlDate((endDay.getMonth() + 1) + "/" + endDay.getDate() + "/" + endDay.getFullYear()));

      $("#vehicle-csv-link").attr("href", vehicleSummaryUrl);
    }

    vehiclesDataTable.ajax.reload();
    vehicleDataTable.ajax.reload();
  });

  function setFpCsvLink() {
    var newDateSelectValue = $("#totals").val();

    if(newDateSelectValue == "FYTD") {
      if ($('#fp-csv-span').length) {
        $("#fp-csv-span").hide();
      }
    } else {
      if ($('#fp-csv-span').length) {
        $("#fp-csv-span").show();
        var theMonth = newDateSelectValue.split(" ")[0];
        var theYear = newDateSelectValue.split(" ")[1];

        var monthNum = moment(theMonth + " 1, " + theYear, 'MMM DD, YYYY').toDate().getMonth();
        var startDay = new Date(theYear, monthNum, 1);
        var endDay = new Date(theYear, monthNum + 1, 0);

        var fpUrl = TableOptions.fuelPurchasesUrl + ".csv?fp_start_date=" + encodeURIComponent(formatUrlDate((startDay.getMonth() + 1) + "/" + startDay.getDate() + "/" + startDay.getFullYear())) + "&fp_end_date=" + encodeURIComponent(formatUrlDate((endDay.getMonth() + 1) + "/" + endDay.getDate() + "/" + endDay.getFullYear()));

        $("#fp-csv-link").attr("href", fpUrl);
      }
    }
  }
});
