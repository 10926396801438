import '@/lib/global_jquery';
import 'bootstrap-sass';
import 'jquery-validation';
import 'jquery-validation/dist/additional-methods';
import moment from 'moment';
import 'multiple-select/dist/multiple-select';
import 'spin.js/spin';
import '@/accordion';
import '@/email_campaigns';
import '@/tables/defaults';
import '@/tables/generic';
import '@/tables/child_nodes';
import '@/tables/children';
import '@/tables/ghg_children';
import '@/tables/children_701';
import '@/tables/vehicles';
import '@/tables/ghg_vehicles';
import '@/tables/vehicles_701';
import '@/tables/inventory';
import '@/tables/search';
import '@/tables/users';
import '@/tables/admin_stations';
import '@/tables/admin_vehicles';
import '@/tables/admin_transactions';
import '@/tables/email_campaign';
import '@/tables/vat_ld';
import '@/tables/vat_hd';
import '@/tables/vat_org_summary';
import '@/tables/vat_org_child_node_summary';
import '@/tables/vat_node_summary';
import '@/tables/vat_node_child_node_summary';
import '@/tables/zev_org_summary';
import '@/tables/zev_node_summary';
import '@/tables/zev_node_child_node_summary';
import '@/tables/zev_ld';
import '@/tables/zev_hd';
import '@/search';
import '@/hierarchy_select';
import '@/tabs';
import '@/print_page';
import '@/charts/fuel_used_constructor';
import '@/charts/fuel_used';
import '@/charts/gge_vs_ghg';
import '@/charts/comp_to_prior_fiscal_yr_constructor';
import '@/charts/comp_to_prior_fiscal_yr';
import '@/charts/comp_to_prior_fiscal_yr_ghg';
import '@/charts/monthly_total_constructor';
import '@/charts/monthly_total';
import '@/charts/monthly_total_ghg';
import '@/charts/vehicle_type_constructor';
import '@/charts/vehicle_type';
import '@/charts/fuel_type_constructor';
import '@/charts/fuel_type';
import '@/charts/section_701_attainment_overall';
import '@/charts/section_701_attainment_comparison';
import '@/charts/section_701_attainment_constructor';
import '@/organization_navigation';
import 'fine-uploader/fine-uploader/fine-uploader';
import '@/vat_transaction_map';
import '@/charts/zev_ld_opportunities';
import '@/charts/zev_hd_opportunities';
import '@/charts/zev_summary_constructor';
import '@/charts/zev_chart_options';

// global object for dataTables
window.TableOptions = {};
window.ChartOptions = {};
window.StationInfo = [];
window.GarageInfo = {};
/**
 * Accept a date as a string or a date object, and ensure it's output in
 * MM/DD/YYYY (with zero-padding) formatting.
 *
 * For example, all of the following should return '02/01/2016':
 *
 * formatUrlDate('02/01/2016');
 * formatUrlDate('02/1/2016');
 * formatUrlDate('2/1/2016');
 * formatUrlDate(new Date('02/01/2016'));
 * formatUrlDate(new Date('2/1/2016'));
 *
 * Empty strings or undefined values will return an empty string (since that's
 * how empty end dates have previously been treated).
 */
window.formatUrlDate = function formatUrlDate(date) {
  if(!date || date === '') {
    return '';
  } else {
    // `date` can either be a string in MM/DD/YYYY (zero padding doesn't matter),
    // or a Date object (in which case the custom format is ignored for parsing).
    return moment(date, 'MM/DD/YYYY').format('MM/DD/YYYY');
  }
}

// create a little array that returns the last 2 fiscal years
window.fiscalArr = function fiscalArr(current_fy_start) {
  //console.log(current_fy_start);

  const fyStartMonth = current_fy_start.split("/")[0];
  const fyStartYear = parseInt(current_fy_start.split("/")[2]);

  //console.log("curMonth=" + curMonth + "  fyStartMonth=" + fyStartMonth + " fyStartYear=" + fyStartYear);

  const fiscalYr = [
    window.formatUrlDate(fyStartMonth + '/01/' + (fyStartYear - 1)),
    window.formatUrlDate(fyStartMonth + '/01/' + fyStartYear),
  ];

  // console.log(fiscalYr[0] + ", " + fiscalYr[1]);

  return fiscalYr;
}
