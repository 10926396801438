import $ from 'jquery';
import DataTable from 'datatables.net-bs';
import moment from 'moment';

$(function () {
  const TableOptions = window.TableOptions;
  const formatUrlDate = window.formatUrlDate;

  // can be changed by radio on vehicle tables
  var startDate = TableOptions.fp_start_date;
  var endDate = TableOptions.fp_end_date;
  var sort_by;

  $("#ghgVehicles .search-month").val("FYTD");

  // specific to the vehicle table
  const dataTable = new DataTable('#ghg_vehicles_table', {
    serverSide: true,
    ordering: true,
    order: [[6, 'desc']],
    'columnDefs': [
      {className: 'justify-right', targets: [1, 2, 3, 4, 5, 6, 7]}
    ],
    'ajax': function (data, callback) {
      sort_by = 'avoidable_ghgs';
      switch (data.order[0].column) {
        case 0:
          sort_by = 'vehicle_internal_id';
          break;
        case 2:
          sort_by = 'alt_gges';
          break;
        case 4:
          sort_by = 'non_alt_missed_opp_gges';
          break;
        case 6:
          sort_by = 'avoidable_ghgs';
          break;
        default:
          sort_by = 'vehicle_internal_id';
          break;
      }

      $.ajax({
        url: TableOptions.vehiclesUrl,
        data: {
          limit: data.length,
          page: data.start/10 + 1,
          fp_start_date: formatUrlDate(startDate),
          fp_end_date: formatUrlDate(endDate),
          sort_order: data.order[0].dir,
          sort_by: sort_by
        },
        success: function (json) {
          var jsonData = [];
          if (json.result) {
            for (var i = 0; i < json.result.length; i++) {
              jsonData.push(json.result[i]);
            }
          }

          var o = {
            recordsTotal: json.metadata.resultset.count,
            recordsFiltered: json.metadata.resultset.count,
            data: jsonData
          };
          callback(o);
        }
      });
    },
    'columns': [
      {'data': function (row) { // column 0
          var nodeId = typeof TableOptions.nodeId === 'undefined' ? '' : TableOptions.nodeId;

          var treeString = '';
          if (TableOptions.nodeId) {
            if (typeof TableOptions.nodeTree !== 'undefined') {
              TableOptions.nodeTree.forEach(function (node) {
                treeString += '&node_tree[]=' + node;
              });
              treeString += '&node_tree[]=' + TableOptions.nodeId;
            } else {
              treeString = '&node_tree[]=' + TableOptions.nodeId;
            }
          }

          return '<a href="/FleetDASH/vehicles/' + row.vehicle_id + '?organization_id=' + TableOptions.organizationId + '&hierarchy_id=' + TableOptions.hierarchyId + '&node_id=' + nodeId + treeString + '">' + row.vehicle_internal_id + '</a>';
        },
        'orderable': true
      },
      { // column 1
        'data': function (row) {
          return Math.round(row.non_alt_missed_opp_gges + row.non_alt_no_missed_opp_gges).toLocaleString();
        },
        'orderable': false
      },
      { // column 2
        'data': function (row) {
          return Math.round(row.alt_gges).toLocaleString();
        },
        'orderable': true
      },
      { // column 3
        'data': function (row) {
          return Math.round(row.non_alt_no_missed_opp_gges + row.alt_gges + row.non_alt_missed_opp_gges).toLocaleString();
        },
        'orderable': false
      },
      { // column 4
        'data': function (row) {
          return Math.round(row.non_alt_missed_opp_gges).toLocaleString();
        },
        'orderable': true
      },
      { // column 5
        'data': function (row) {
          return Math.round(row.non_alt_no_missed_opp_ghgs + row.alt_ghgs + row.non_alt_missed_opp_ghgs).toLocaleString();
        },
        'orderable': false
      },
      { // column 6
        'data': function (row) {
          return Math.round(row.avoidable_ghgs).toLocaleString();
        },
        'orderable': true
      },
      { // column 7
        'data': function (row) {
          return ((row.non_alt_no_missed_opp_ghgs + row.alt_ghgs + row.non_alt_missed_opp_ghgs) / 
            (row.non_alt_no_missed_opp_gges + row.alt_gges + row.non_alt_missed_opp_gges)).toFixed(1).toLocaleString();
        },
        'orderable': false
      },
    ]
  });

  var fytdStartDate = TableOptions.fp_start_date;
  var fytdEndDate = TableOptions.fp_end_date;

  $(document).on('change', '#ghgVehicles .search-month', function () {
    setFpCsvLink();

    var selectedMonth = $("#ghgVehicles .search-month").val();

    var startDay;
    var endDay;

    if ($(this).val() === 'FYTD') {
      startDay = moment(fytdStartDate, 'MM/DD/YYYY').toDate();
      const latestTxnMonth = moment(TableOptions.latestTxnData.split("/")[0] + "/01/" + TableOptions.latestTxnData.split("/")[1], 'MM/DD/YYYY').toDate();
      endDay = new Date(latestTxnMonth.getFullYear(), latestTxnMonth.getMonth() + 1, 0);
      startDate = formatUrlDate(fytdStartDate);
      endDate = formatUrlDate(fytdEndDate);
    } else {
      var theMonth = selectedMonth.split(" ")[0];
      var theYear = selectedMonth.split(" ")[1];

      var monthNum = moment(theMonth + " 1, " + theYear, 'MMM DD, YYYY').toDate().getMonth();
      startDay = new Date(theYear, monthNum, 1);
      endDay = new Date(theYear, monthNum + 1, 0);
      startDate = formatUrlDate(startDay.getMonth() + 1 + "/" + startDay.getDate() + "/" + startDay.getFullYear());
      endDate = formatUrlDate(endDay.getMonth() + 1 + "/" + endDay.getDate() + "/" + endDay.getFullYear());
    }

    if ($('#ghg_vehicles_table').length) {
      var vehicleSummaryUrl = TableOptions.vehiclesUrl + ".csv?fp_start_date=" + encodeURIComponent(formatUrlDate((startDay.getMonth() + 1) + "/" + startDay.getDate() + "/" + startDay.getFullYear())) + "&fp_end_date=" + encodeURIComponent(formatUrlDate((endDay.getMonth() + 1) + "/" + endDay.getDate() + "/" + endDay.getFullYear()));

      $("#ghg-vehicle-csv-link").attr("href", vehicleSummaryUrl);
    }

    dataTable.ajax.reload();
  });

  function setFpCsvLink() {
    var newDateSelectValue = $("#totals").val();

    if(newDateSelectValue == "FYTD") {
      if ($('#fp-csv-span').length) {
        $("#fp-csv-span").hide();
      }
    } else {
      if ($('#fp-csv-span').length) {
        $("#fp-csv-span").show();
        var theMonth = newDateSelectValue.split(" ")[0];
        var theYear = newDateSelectValue.split(" ")[1];

        var monthNum = moment(theMonth + " 1, " + theYear, 'MMM DD, YYYY').toDate().getMonth();
        var startDay = new Date(theYear, monthNum, 1);
        var endDay = new Date(theYear, monthNum + 1, 0);

        var fpUrl = TableOptions.fuelPurchasesUrl + ".csv?fp_start_date=" + encodeURIComponent(formatUrlDate((startDay.getMonth() + 1) + "/" + startDay.getDate() + "/" + startDay.getFullYear())) + "&fp_end_date=" + encodeURIComponent(formatUrlDate((endDay.getMonth() + 1) + "/" + endDay.getDate() + "/" + endDay.getFullYear()));

        $("#fp-csv-link").attr("href", fpUrl);
      }
    }
  }
});
