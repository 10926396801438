import Highcharts from '@/lib/highcharts_with_modules';
import zevChartOptions from '@/charts/zev_chart_options';

export default function zevLdOpportunitiesGraph(data) {
  Highcharts.chart("zevLdOpportunitiesSummaryGraph", {
    tooltip: {
      useHTML: true,
      formatter: function() {
        return `<b>${this.point.name}: </b> ${Math.round(this.y).toLocaleString()}% Opportunities`;
      }
    },
    title: {
      text: "Light-Duty EV Opportunities",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        showInLegend: true,
        dataLabels: {
          alignTo: 'plotEdges',
          style: {
            fontSize: '12px',
          },
          formatter: function () {
            const percentage = Math.round(this.percentage);
            return `${this.point.name.toUpperCase()} ${percentage}%`;
          },
        },
      }
    },
    series: [
      {
        type: "pie",
        name: "EV LD Opportunities",
        data: zevChartOptions(data),
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG', 'separator', 'downloadCSV', 'downloadXLS']
        }
      },
    }
  });
}
