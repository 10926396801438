import $ from 'jquery';
import zevHdOpportunitiesGraph from '@/charts/zev_hd_opportunities';
import zevLdOpportunitiesGraph from '@/charts/zev_ld_opportunities';

$(function () {
  const ChartOptions = window.ChartOptions;

  if (ChartOptions.zevOpportunitiesUrl) {
    $.when(
      $.getJSON(ChartOptions.zevOpportunitiesUrl),
    ).done(function(currentFyData) {
      var ldData = null;
      var hdData = null;
      if (currentFyData.result) {
        ldData = currentFyData.result.find(function(data){ return data.vehicle_class === "LD" });
        hdData = currentFyData.result.find(function(data){ return data.vehicle_class === "HD" });
      }
      zevLdOpportunitiesGraph(ldData);
      zevHdOpportunitiesGraph(hdData);
    }).fail(function(err) {
      console.error('ajax error: ', err);
    });
  }
});
