import $ from 'jquery';
import DataTable from 'datatables.net-bs';

$(function () {
  new DataTable('#transaction_upload_processed', {
    searching: true,
    ordering: true,
    order: [6, 'desc']
  });
});
