import $ from 'jquery';
import DataTable from 'datatables.net-bs';

$(function () {
  const TableOptions = window.TableOptions;

  new DataTable('#station-management', {
    serverSide: true,
    searching: true,
    ordering: true,
    'ajax': function (data, callback) {
      $.ajax({
        url: TableOptions.stationsUrl,
        data: {
          limit: 10,
          page: data.start/10 + 1,
          tab: "stations",
          column: data.order[0].column,
          direction: data.order[0].dir,
          search: data.search.value
        },
        success: function (json) {
          var o = {
            recordsTotal: TableOptions.stationsTotal,
            recordsFiltered: json.count,
            data: JSON.parse(json.result)
          };

          callback(o);
        }
      });
    },
    'columns': [
      {
        'data': function (row) {
          return '<a href="/FleetDASH/fuel_stations/' + row.id + '/edit">' + row.name + '</a>';
        }
      },
      {
        'data': 'street_address',
        'orderable': true
      },
      {
        'data': 'city',
        'orderable': true
      },
      {
        'data': 'state',
        'orderable': true
      },
      {
        'data': 'zipcode',
        'orderable': true
      },
      {
        'data': function (row) {
          if (row.is_afdc) {
            return '<span class="glyphicon glyphicon-ok"></span>';
          }
          return '';
        },
        'orderable': true
      },
      {
        'data': function (row) {
          if (row.is_fed_stn) {
            return '<span class="glyphicon glyphicon-ok"></span>';
          }
          return '';
        },
        'orderable': true
      },
      {
        'data': 'manual_latitude',
        'orderable': true
      },
      {
        'data': 'manual_longitude',
        'orderable': true
      },
      {
        'data': 'known_coding_issues',
        'orderable': true
      }
    ]
  });
});
