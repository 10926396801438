import Highcharts from '@/lib/highcharts_with_modules';

export default function section701SummaryGraph (data) {
  Highcharts.chart("section701AttainmentOverallGraph", {
    tooltip: {
      useHTML: true,
      formatter: function() {
        return "<b>"+ this.point.name + ": </b> "+ Math.round(this.y).toLocaleString() +" GGE";
      }
    },
    title: { 
      text: "FleetDASH 701 Attainment",
    }, 
    plotOptions: { 
      pie: { 
        allowPointSelect: true, 
        cursor: "pointer", 
        showInLegend: true,
        dataLabels: {
          distance: '-25%',
          style: {
            fontSize: '26px',
            color: '#fff',
          },
          formatter: function () {
            const percentage = Math.round(this.percentage);
            if (percentage > 0) {
              return percentage + "%";
            }
          },
        },
      } 
    }, 
    series: [ 
      { 
        type: "pie", 
        name: "Individual Fuel Types",
        data: [ 
          { 
            name: "FleetDASH 701 Attainment",
            y: data.alt_gges_total,
            color: "#005B82"
          }, 
          { 
            name: "Covered Missed Opportunities",
            y: data.missed_opp_701_gges,
            color: "#E37222"
          },
        ],
        innerSize: '50%',
      }, 
    ], 
    legend: { 
      enabled: true,
    }, 
    credits: { 
      enabled: false 
    }, 
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG', 'separator', 'downloadCSV', 'downloadXLS']
        }
      },
    } 
  });
}
