export default function zevChartOptions(data) {
  var apiData = null;
  if (data) {
    apiData = [
      {
        name: "Good or Great",
        y: data.zev_candidate_good_pct + data.zev_candidate_great_pct,
        color: "#71AD47"
      },
      {
        name: "Mediocre",
        y: data.zev_candidate_mediocre_pct,
        color: "#E2F0D9"
      },
      {
        name: "Challenging",
        y: data.zev_candidate_challenging_pct,
        color: "#FFD966"
      },
      {
        name: "No EV Option",
        y: data.zev_candidate_no_option_pct,
        color: "#7F7F7F"
      },
      {
        name: "Current EV",
        y: data.current_zev_pct,
        color: "#2F75B6"
      }
    ]
  };
  return apiData;
}
