import $ from 'jquery';
import DataTable from 'datatables.net-bs';

$(function () {
  const TableOptions = window.TableOptions;
  const formatUrlDate = window.formatUrlDate;

  // can be changed by radio on vehicle tables
  var startDate = TableOptions.fp_start_date;
  var endDate = TableOptions.fp_end_date;

  // $("#vehicles701 .search-month").val("FYTD");

  // specific to the vehicle table
  new DataTable('#vehicles_701_table', {
    serverSide: true,
    ordering: true,
    order: [[1, 'desc']],
    'columnDefs': [
      {className: 'justify-right', targets: [1, 2, 3, 4, 5]}
    ],
    'ajax': function (data, callback) {
      $.ajax({
        url: TableOptions.vehicles701Url,
        data: {
          limit: data.length,
          page: data.start/10 + 1,
          fp_start_date: formatUrlDate(startDate),
          fp_end_date: formatUrlDate(endDate),
          sort_order: data.order[0].dir,
        },
        success: function (json) {
          var jsonData = [];
          if (json.result) {
            for (var i = 0; i < json.result.length; i++) {
              jsonData.push(json.result[i]);
            }
          }

          var o = {
            recordsTotal: json.metadata.resultset.count,
            recordsFiltered: json.metadata.resultset.count,
            data: jsonData
          };
          callback(o);
        }
      });
    },
    'columns': [
      {
        'data': function (row) { // column 0
          var nodeId = typeof TableOptions.nodeId === 'undefined' ? '' : TableOptions.nodeId;

          var treeString = '';
          if (TableOptions.nodeId) {
            if (typeof TableOptions.nodeTree !== 'undefined') {
              TableOptions.nodeTree.forEach(function (node) {
                treeString += '&node_tree[]=' + node;
              });
              treeString += '&node_tree[]=' + TableOptions.nodeId;
            } else {
              treeString = '&node_tree[]=' + TableOptions.nodeId;
            }
          }

          return '<a href="/FleetDASH/vehicles/' + row.vehicle_id + '?organization_id=' + TableOptions.organizationId + '&hierarchy_id=' + TableOptions.hierarchyId + '&node_id=' + nodeId + treeString + '">' + row.vehicle_internal_id + '</a>';
        },
        'orderable': true
      },
      {
        'data': 'vehicle_fuel_type', // column 1
        'orderable': true
      },
      {
        'data': function (row) {
          var status_701;
          if (row.vehicle_covered_701 && row.vehicle_exempt_701) {
            status_701 = "Exempt";
          } else if (row.vehicle_covered_701 && !row.vehicle_exempt_701) {
            status_701 = "Covered";
          } else {
            status_701 = "Unknown";
          }
          return status_701;
        }, // column 1
        'orderable': false
      },
      {
        'data': function (row) { // column 2
          return Math.round(row.alt_gges).toLocaleString();
        },
        'orderable': false
      },
      {
        'data': function (row) { // column 3
          if(row.vehicle_fuel_type === 'PHEV' || row.vehicle_fuel_type === 'PHEV/FFV') {
            return Math.round(row.non_alt_missed_opp_gges + row.phev_missed_opp_gges).toLocaleString();
          } else {
            return Math.round(row.non_alt_missed_opp_gges).toLocaleString();
          }
        },
        'orderable': false
      },
      {
        'data': "attainment_701",
        'orderable': true
      }
    ]
  });
});
