import $ from 'jquery';
import compToPriorFiscalYearGhgGraph from '@/charts/comp_to_prior_fiscal_yr_ghg';
import compToPriorFiscalYearGraph from '@/charts/comp_to_prior_fiscal_yr';

$(function () {
  const ChartOptions = window.ChartOptions;
  const fiscalArr = window.fiscalArr;

  var data = {
    lastYear: {
      name: '',
      data: [0,0,0]
    },
    thisYear: {
      name: '',
      data: [0,0,0]
    }
  };

  var ghgData = {
    lastYearName: '',
    thisYearName: '',
    series: [
      {
        name: 'ELEC',
        data: [0,0],
        color: '#0485A8'
      },
      {
        name: 'CNG',
        data: [0,0],
        color: '#07B0DD'
      },
      {
        name: 'BD',
        data: [0,0],
        color: '#0698BF'
      },
      {
        name: 'E85',
        data: [0,0],
        color: '#026C91'
      },
      {
        name: 'DSL',
        data: [0,0],
        color: '#C3C3C3'
      },
      {
        name: 'GAS',
        data: [0,0],
        color: '#A2A1A1'
      },
      {
        name: 'Missed Opps',
        data: [0,0],
        color: '#E37222'
      },
    ]
  };

  function parseFytdSummaryData (current_fy_start, result) {
    // console.log("current_fy_start: " + current_fy_start);
    var thisYear = fiscalArr(current_fy_start)[1].split('/');
    thisYear[2] = parseInt(thisYear[2]) + 1;

    data.thisYear.name = thisYear[2];
    // console.log("thisYear.name: " + data.thisYear.name);
    data.lastYear.name = parseInt(fiscalArr(current_fy_start)[0].split('/')[2]) + 1;
    // console.log("lastYear.name: " + data.lastYear.name);


    // console.log("thisYear = " + thisYear);
    var currentFiscal = new Date(thisYear[2] - 1, thisYear[0] - 1, thisYear[1]);
    // console.log("currentFiscal = " + currentFiscal);

    var currentLimit = new Date(ChartOptions.latestTxnData.split('/')[1] - 1, ChartOptions.latestTxnData.split('/')[0], 0);
    // console.log(currentLimit);

    $.each(result, function(fCat) {
      if (fCat === 'non_alt_missed_opp_gges') {
        $.each(result[fCat], function(month, types) {
          var splitMonth = month.split('-');
          var thisDate = new Date(splitMonth[0], splitMonth[1] - 1, splitMonth[2]);
          $.each(types, function(type, val) {
            // console.log(thisDate, currentFiscal, fCat);
            // if its from years past
            if (thisDate < currentFiscal) {
              // console.log(thisDate, currentFiscal, currentLimit, fCat);
              if (thisDate < currentLimit) {
                data.lastYear.data[2] += val;
                data.lastYear.data[0] += val;
              }
            } else {
              data.thisYear.data[2] += val;
              data.thisYear.data[0] += val;
            }
          });
        });
      } else if (fCat === 'non_alt_no_missed_opp_gges') {
        $.each(result[fCat], function(month, types) {
          var splitMonth = month.split('-');
          var thisDate = new Date(splitMonth[0], splitMonth[1] - 1, splitMonth[2]);
          $.each(types, function(type, val) {
            if (thisDate < currentFiscal) {
              if (thisDate < currentLimit) {
                data.lastYear.data[0] += val;
              }
            } else {
              data.thisYear.data[0] += val;
            }
          });
        });
      } else if (fCat === 'alt_gges') {
        $.each(result[fCat], function(month, types) {
          var splitMonth = month.split('-');
          var thisDate = new Date(splitMonth[0], splitMonth[1] - 1, splitMonth[2]);
          $.each(types, function(type, val) {
            if (thisDate < currentFiscal) {
              if (thisDate < currentLimit) {
                data.lastYear.data[1] += val;
              }
            } else {
              data.thisYear.data[1] += val;
            }
          });
        });
      }
    });

    // data.lastYear.data = [1001,0,0]; // for testing usps
    compToPriorFiscalYearGraph(data);
  }

  function parseFytdSummaryGhgData (current_fy_start, result) {
    var thisYear = fiscalArr(current_fy_start)[1].split('/');
    thisYear[2] = parseInt(thisYear[2]) + 1;

    ghgData.thisYearName = thisYear[2];
    ghgData.lastYearName = parseInt(fiscalArr(current_fy_start)[0].split('/')[2]) + 1;

    var currentFiscal = new Date(thisYear[2] - 1, thisYear[0] - 1, thisYear[1]);
    var currentLimit = new Date(ChartOptions.latestTxnData.split('/')[1] - 1, ChartOptions.latestTxnData.split('/')[0], 0);

    $.each(result, function(fCat) {
      if (fCat === 'non_alt_missed_opp_ghgs') {
        $.each(result[fCat], function(month, types) {
          var splitMonth = month.split('-');
          var thisDate = new Date(splitMonth[0], splitMonth[1] - 1, splitMonth[2]);
          $.each(types, function(type, val) {
            if (thisDate < currentFiscal) {
              if (thisDate < currentLimit) {
                ghgData.series[6].data[0] += val;
              }
            } else {
              ghgData.series[6].data[1] += val;
            }
          });
        });
      } else if (fCat === 'non_alt_no_missed_opp_ghgs') {
        $.each(result[fCat], function(month, types) {
          var splitMonth = month.split('-');
          var thisDate = new Date(splitMonth[0], splitMonth[1] - 1, splitMonth[2]);
          $.each(types, function(type, val) {
            if (thisDate < currentFiscal) {
              if (thisDate < currentLimit) {
                if (type === 'GAS') {
                  ghgData.series[5].data[0] += val;
                } else if (type === 'DSL') {
                  ghgData.series[4].data[0] += val;
                }
              }
            } else {
              if (type === 'GAS') {
                ghgData.series[5].data[1] += val;
              } else if (type === 'DSL') {
                ghgData.series[4].data[1] += val;
              }
            }
          });
        });
      } else if (fCat === 'alt_ghgs') {
        $.each(result[fCat], function(month, types) {
          var splitMonth = month.split('-');
          var thisDate = new Date(splitMonth[0], splitMonth[1] - 1, splitMonth[2]);
          $.each(types, function(type, val) {
            if (thisDate < currentFiscal) {
              if (thisDate < currentLimit) {
                if (type === 'BD') { 
                  ghgData.series[2].data[0] += val;
                } else if (type === 'E85') {
                  ghgData.series[3].data[0] += val;
                } else if (type === 'CNG') {
                  ghgData.series[1].data[0] += val;
                } else if (type === 'ELEC') {
                  ghgData.series[0].data[0] += val;
                }
              }
            } else {
              if (type === 'BD') { 
                ghgData.series[2].data[1] += val;
              } else if (type === 'E85') {
                ghgData.series[3].data[1] += val;
              } else if (type === 'CNG') {
                ghgData.series[1].data[1] += val;
              } else if (type === 'ELEC') {
                ghgData.series[0].data[1] += val;
              }
            }
          });
        });
      }
    });

    compToPriorFiscalYearGhgGraph(ghgData);
  }

  // make the ajax call
  if (ChartOptions.fytdSummaryUrl) {
    //console.log(ChartOptions.fytdSummaryUrl);
    $.getJSON(ChartOptions.fytdSummaryUrl, function (data) {
      var monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      $('.comparison-note').html('* Comparison through ' + monthNames[parseInt(ChartOptions.latestTxnData.split("/")[0]) - 1]);
      parseFytdSummaryData(ChartOptions.fp_start_date, data.result);
      parseFytdSummaryGhgData(ChartOptions.fp_start_date, data.result);
    });
  }
});
