import Highcharts from '@/lib/highcharts_with_modules';

export default function compToPriorFiscalYearGhgGraph (data) {
  Highcharts.chart("ghgPriorGraph", {
    chart: {
      type: "column"
    },
    title: { 
      text: "Comparison to Prior Fiscal Year" 
    }, 
    xAxis: {
      categories: ['FY ' + data.lastYearName, 'FY ' + data.thisYearName]
    },
    yAxis: {
      title: {
        text: "kg CO2e"
      }
    },
    tooltip: {
      useHTML: true,
      formatter: function() {
        // console.log(this);
        return "<b>"+ this.x + ": </b> "+ Math.round(this.y).toLocaleString() +" kg CO2e";
      }
    },
    plotOptions: { 
      column: { 
        // allowPointSelect: true, 
        cursor: "pointer", 
        stacking: 'normal',
        showInLegend: true,
        dataLabels: {
          enabled: false,
        }
      } 
    }, 
    series: data.series, 
    legend: { 
      enabled: true,
      align: "right",
      verticalAlign: "middle",
      layout: "vertical"
    }, 
    credits: { 
      enabled: false 
    }, 
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG', 'separator', 'downloadCSV', 'downloadXLS']
        }
      },
    } 
  });
}
