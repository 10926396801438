import $ from 'jquery';

$('#organization-navigation').on('change', function () {
  // stupid workaround for stupid IE
  if (!window.location.origin) {
    window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
  }

  window.location = window.location.origin + '/FleetDASH/organizations/' + $(this).val();
});
