import Highcharts from '@/lib/highcharts_with_modules';

export default function monthlyTotalGhgGraph (datum) {
  var data = datum.data;

  Highcharts.stockChart("monthly_total_ghg", {
    chart: {
      type: "column", 
      spacingRight: 24
    },
    navigator: {
      baseSeries: 2,
      height: 20,
      series: {
        visible: false,
        color: "white",
        lineColor: "white"
      }
    },
    rangeSelector: {
      buttons: [{
        type: "month",
        count: 6,
        text: "6m"
      }, {
        type: "year",
        count: 1,
        text: "1y"
      }, {
        type: "all",
        text: "All"
      }],
      inputEnabled: false
    },
    title: { 
      text: ""
    }, 
    xAxis: { 
      title: { 
        text: "Month" 
      },
    }, 
    tooltip: {
      shared: false,
      split: false,
      formatter: function() {
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", "January"]; // not sure why I need January twice
        var date = new Date(this.x);
        var dateLine = "";
        if (date.getMonth() == 11) {
          dateLine = "<b>" + months[date.getMonth() + 1] + " " + (date.getFullYear() + 1) + "</b><br>";
        } else {
          dateLine = "<b>" + months[date.getMonth() + 1] + " " + date.getFullYear() + "</b><br>";
        }
        if (typeof this.total !== "undefined") {
          var line1 = "<b>"+ this.series.name + ": </b> "+ Math.round(this.y).toLocaleString() +" kg CO2e<br>";
          var line2 = "<b>Total: </b> "+ Math.round(this.total).toLocaleString() +" kg CO2e";
          return dateLine + line1 + line2;
        } else {
          return dateLine + "<b>"+ this.series.name + ": </b> "+ Math.round(this.y).toLocaleString() +" kg CO2e";
        }
      }
    },
    yAxis: { 
      allowDecimals: false, 
      min: 0, 
      title: { 
        text: "kg CO2e" 
      } 
    }, 
    plotOptions: { 
      column: { 
        stacking: "normal"
      }, 
      series: {
        stickyTracking: true
      }
    }, 
    legend: { 
      enabled: true, 
      align: "top",
      verticalAlign: "top",
      layout: "horizontal",
      x: 6, 
      symbolPadding: 4, 
      symbolWidth: 14
    },
    credits: { 
      enabled: false 
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG', 'separator', 'downloadCSV', 'downloadXLS']
        }
      },
    },
    series: data
  });
}
