import $ from 'jquery';
import DataTable from 'datatables.net-bs';

$(function() {
  const TableOptions = window.TableOptions;
  const getQueryString = window.getQueryString;

  var defaultContent = 'N/A';
  var columns = [
    {
      name: 'node_name',
      orderable: true,
      className: 'heavy-border-right',
      data: function (row) {
        var name;
        if (row.node_alt_name === null || row.node_alt_name === '') {
          name = row.node_name;
        } else {
          name = row.node_alt_name;
        }

        return '<a href="/FleetDASH/nodes/' + row.node_id + '?organization_id=' + TableOptions.organizationId + '&hierarchy_id=' + TableOptions.hierarchyId + '&tab=' + getQueryString().tab + '">' + name + '</a>';
      }
    },
    {
      name: 'total_vehicle_count',
      data: 'total_vehicle_count',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: true,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'ev_replacement_counts',
      data: 'ev_replacement_counts',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'phev_replacement_counts',
      data: 'phev_replacement_counts',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'hev_replacement_counts',
      data: 'hev_replacement_counts',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'e85_replacement_counts',
      data: 'e85_replacement_counts',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'b20_replacement_counts',
      data: 'b20_replacement_counts',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'cng_replacement_counts',
      data: 'cng_replacement_counts',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'lpg_replacement_counts',
      data: 'lpg_replacement_counts',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'e85_availability_gges_pct',
      data: 'e85_availability_gges_pct',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0, null, '%'),
    },
    {
      name: 'b20_availability_gges_pct',
      data: 'b20_availability_gges_pct',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0, null, '%'),
    },
    {
      name: 'cng_availability_gges_pct',
      data: 'cng_availability_gges_pct',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0, null, '%'),
    },
    {
      name: 'lpg_availability_gges_pct',
      data: 'lpg_availability_gges_pct',
      className: 'justify-right heavy-border-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0, null, '%'),
    },
  ];
  var columnIndexByName = columns.reduce(function(byName, col, index) { byName[col.name] = index; return byName }, {});

  new DataTable('#vat_org_child_node_summary_table', {
    serverSide: false,
    ordering: true,
    order: [[columnIndexByName.node_name, 'desc']],
    ajax: function (data, callback) {
      $.ajax({
        url: TableOptions.vatOrgChildNodeSummaryUrl,
        data: {
          fiscal_year: TableOptions.vatFiscalYear,
          combine: "y",
        },
        success: function (json) {
          var jsonData = [];
          if (json.result) {
            for (var i = 0; i < json.result.length; i++) {
              jsonData.push(json.result[i]);
            }
          }

          var o = {
            recordsTotal: json.metadata.resultset.count,
            recordsFiltered: json.metadata.resultset.count,
            data: jsonData
          };
          callback(o);
        }
      });
    },
    columns: columns,
  });
});
