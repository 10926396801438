import $ from 'jquery';
import DataTable from 'datatables.net-bs';

$(function() {
  const TableOptions = window.TableOptions;

  var createdCellHighlight = function(td, cellData, rowData, row, col) {
    var fuelCostDifference;

    switch (col) {
      case 5:
        if (rowData.ev_annual_operating_cost === null) return
        fuelCostDifference = rowData.low_bid_annual_operating_cost - rowData.ev_annual_operating_cost;
        break;
      case 6:
        if (rowData.phev_annual_operating_cost === null) return
        fuelCostDifference = rowData.low_bid_annual_operating_cost - rowData.phev_annual_operating_cost;
        break;
      case 7:
        if (rowData.hev_annual_operating_cost === null) return
        fuelCostDifference = rowData.low_bid_annual_operating_cost - rowData.hev_annual_operating_cost;
        break;
      case 8:
        if (rowData.e85_annual_operating_cost === null) return
        fuelCostDifference = rowData.low_bid_annual_operating_cost - rowData.e85_annual_operating_cost;
        break;
    }

    if (fuelCostDifference < 0) {
      $(td).addClass('vat-cost-shading-4');
    } else if (fuelCostDifference > 500) {
      $(td).addClass('vat-cost-shading-1');
    } else if (fuelCostDifference < 500 && fuelCostDifference > 250) {
      $(td).addClass('vat-cost-shading-2');
    } else if (fuelCostDifference < 250 && fuelCostDifference >= 0) {
      $(td).addClass('vat-cost-shading-3');
    }
  }

  var defaultContent = 'N/A';
  var columns = [
    {
      name: 'vin',
      data: 'vin',
      render: function(data, type, row) {
        return '<a href="/FleetDASH/vehicles/' + row.vehicle_id + '/vat?organization_id=' + TableOptions.organizationId + '&hierarchy_id=' + TableOptions.hierarchyId + '">' + data + '</a>';
      },
      orderable: false,
    },
    {
      name: 'internal_id',
      data: 'internal_id',
      orderable: false,
    },
    {
      name: 'vehicle_segment',
      data: 'vehicle_segment',
      orderable: false,
    },
    {
      name: 'fleetdash_fuel_type',
      data: 'fleetdash_fuel_type',
      orderable: false,
    },
    {
      name: 'total_gges',
      data: 'total_gges',
      orderable: true,
      className: 'justify-right',
      defaultContent: defaultContent,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'ev_gges_reduced',
      data: 'ev_gges_reduced',
      orderable: true,
      className: 'justify-right',
      defaultContent: defaultContent,
      render: DataTable.render.number(',', '.', 0),
      createdCell: createdCellHighlight,
    },
    {
      name: 'phev_gges_reduced',
      data: 'phev_gges_reduced',
      orderable: true,
      className: 'justify-right',
      defaultContent: defaultContent,
      render: DataTable.render.number(',', '.', 0),
      createdCell: createdCellHighlight,
    },
    {
      name: 'hev_gges_reduced',
      data: 'hev_gges_reduced',
      orderable: true,
      className: 'justify-right',
      defaultContent: defaultContent,
      render: DataTable.render.number(',', '.', 0),
      createdCell: createdCellHighlight,
    },
    {
      name: 'e85_gges_reduced',
      data: 'e85_gges_reduced',
      orderable: true,
      className: 'justify-right',
      defaultContent: defaultContent,
      render: DataTable.render.number(',', '.', 0),
      createdCell: createdCellHighlight,
    },
  ];
  var columnIndexByName = columns.reduce(function(byName, col, index) { byName[col.name] = index; return byName }, {});

  new DataTable('#vat_ld_summary_table', {
    serverSide: true,
    searching: true,
    ordering: true,
    order: [[columnIndexByName.ev_gges_reduced, 'desc']],
    ajax: function (data, callback) {
      var order_column = data.order[0].column;
      var sort_by = columns[order_column].name;

      $.ajax({
        url: TableOptions.vatLdSummaryUrl,
        data: {
          fiscal_year: TableOptions.vatFiscalYear,
          limit: data.length,
          page: data.start/10 + 1,
          sort_order: data.order[0].dir,
          sort_by: sort_by,
          search: data.search.value,
        },
        success: function (json) {
          var jsonData = [];
          if (json.result) {
            for (var i = 0; i < json.result.length; i++) {
              jsonData.push(json.result[i]);
            }
          }

          var o = {
            recordsTotal: json.metadata.resultset.count,
            recordsFiltered: json.metadata.resultset.count,
            data: jsonData
          };
          callback(o);
        }
      });
    },
    columns: columns,
  });
});
