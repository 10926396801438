import $ from 'jquery';

(function () {
  var $panel = $('.panel-collapse');

  $panel.on('show.bs.collapse', function () {
    $(this).parent().find('.chevron').removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down');
  });

  $panel.on('hide.bs.collapse', function () {
    $(this).parent().find('.chevron').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up');
  });
})();
