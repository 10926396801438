import Highcharts from '@/lib/highcharts_with_modules';

export default function vehicleTypeGraph (seriesData) {
  Highcharts.chart("vehicleTypeGraph", {
    tooltip: {
      useHTML: true,
      formatter: function() {
        return "<b>"+ this.point.name + ": </b> "+ Math.round(this.y).toLocaleString() +" Vehicles";
      },
      valueDecimals: 2
    },
    title: { 
      text: "Vehicle Weight and Type" 
    }, 
    plotOptions: { 
      pie: { 
        allowPointSelect: true, 
        cursor: "pointer", 
        showInLegend: true,
        dataLabels: {
          formatter: function () {
            if (parseFloat(this.percentage.toFixed(2)) >= 1) {
              return "<b>" + this.point.name + "</b><br>" + this.percentage.toFixed(0) + "%";
            }
          }
        }
      } 
    }, 
    series: seriesData, 
    legend: { 
      // TODO if nobody asks for the legend, clean this up
      enabled: false,
      align: "right",
      verticalAlign: "middle",
      layout: "vertical"
    }, 
    credits: { 
      enabled: false 
    }, 
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG', 'separator', 'downloadCSV', 'downloadXLS']
        }
      },
    } 
  });
}
