import $ from 'jquery';
import DataTable from 'datatables.net-bs';

$(function () {
  const TableOptions = window.TableOptions;
  const getQueryString = window.getQueryString;

  $('#csv-link').prepend('<a href="' + TableOptions.vehicleCountsUrl + '.csv">Download CSV</a>');

  function columns () {
    if (TableOptions.isGsa) {
      return [
        {'data': function (row) {
            var name = (row.node_alt_name !== null && row.node_alt_name !== '') ? row.node_alt_name : row.node_name;
            return '<a href="/FleetDASH/nodes/' + row.root_node_id + '?organization_id=' + TableOptions.organizationId + '&hierarchy_id=' + TableOptions.hierarchyId + '&tab=' + getQueryString().tab + '">' + name + '</a>';
        }},
        {'data': function (row) {
          return row.total_count.toLocaleString();
        }},
        {'data': function (row) {
          return row.net_change.toLocaleString();
        }},
        {'data': function (row) {
          return row.home_to_work.toLocaleString();
        }},
        {'data': function (row) {
          return row.executive.toLocaleString();
        }},
        {'data': function (row) {
          return row.leer.toLocaleString();
        }},
        {'data': function (row) {
          return row.average_vehicle_age.toLocaleString();
        }}
      ];
    } else {
      return [
        {'data': function (row) {
          var name = (row.node_alt_name !== null && row.node_alt_name !== '') ? row.node_alt_name : row.node_name;
            return '<a href="/FleetDASH/nodes/' + row.root_node_id + '?organization_id=' + TableOptions.organizationId + '&hierarchy_id=' + TableOptions.hierarchyId + '&tab=' + getQueryString().tab + '">' + name + '</a>';
        }},
        {'data': function (row) {
          return row.total_count.toLocaleString();
        }},
        {'data': function (row) {
          return row.net_change.toLocaleString();
        }},
        {'data': function (row) {
          return row.leer.toLocaleString();
        }},
        {'data': function (row) {
          return row.average_vehicle_age.toLocaleString();
        }}
      ];
    }
  }

  // for the inventory table
  new DataTable('#inventory_table', {
    searching: true,
    ordering: true,
    order: [1, 'desc'],
    'columnDefs': [
      { className: 'justify-right', 'targets': [1, 2, 3, 4] }
    ],
    'ajax': function (data, callback) {
      $.ajax({
        url: TableOptions.vehicleCountsUrl,
        success: function (json) {
          var dataArr = [];
          $('#inventory-dates').html(json.metadata.month + ' ' + json.metadata.year);
          for ( var i=0; i<json.result.length; i++ ) {
            dataArr.push(json.result[i]);
          }

          var o = {
            recordsTotal: json.metadata.resultset.count,
            recordsFiltered: json.metadata.resultset.count,
            data: dataArr
          };

          callback(o);
        }
      });
    },
    'columns': columns(),
    'footerCallback': function() {
      var api = this.api();
      if (api.column(1).data().length > 0) {
        $(api.column(0).footer()).html('TOTALS');

        $(api.column(1).footer()).html(
          api.column(1).data().reduce(function (a, b) {
            a = '' + a;
            b = '' + b;
            return parseInt(a.replace(',', '')) + parseInt(b.replace(',', ''));
          }).toLocaleString()
        );

        $(api.column(2).footer()).html(
          api.column(2).data().reduce(function (a, b) {
            a = '' + a;
            b = '' + b;
            return parseInt(a.replace(',', '')) + parseInt(b.replace(',', ''));
          }).toLocaleString()
        );

        $(api.column(3).footer()).html(
          api.column(3).data().reduce(function (a, b) {
            a = '' + a;
            b = '' + b;
            return parseInt(a.replace(',', '')) + parseInt(b.replace(',', ''));
          }).toLocaleString()
        );

        if (api.columns()['0'].length > 5) {
          $(api.column(4).footer()).html(
              api.column(4).data().reduce(function (a, b) {
                a = '' + a;
                b = '' + b;
                return parseInt(a.replace(',', '')) + parseInt(b.replace(',', ''));
              }).toLocaleString()
          );
          $(api.column(5).footer()).html(
              api.column(5).data().reduce(function (a, b) {
                a = '' + a;
                b = '' + b;
                return parseInt(a.replace(',', '')) + parseInt(b.replace(',', ''));
              }).toLocaleString()
          );
          // this needs to be an average
          const total = api.column(6).data().reduce(function (a, b) {
            return parseInt(a) + parseInt(b);
          });
          const length = api.column(6).data().length;
          $(api.column(6).footer()).html((total/length).toFixed(0).toLocaleString());
        }
        else {
          // this needs to be an average
          const total = api.column(4).data().reduce(function (a, b) {
            return parseInt(a) + parseInt(b);
          });
          const length = api.column(4).data().length;
          $(api.column(4).footer()).html((total/length).toFixed(0).toLocaleString());
        }
      }
    }
  });
});
