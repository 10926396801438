import $ from 'jquery';
import fuelUsedGraph from '@/charts/fuel_used';
import ggeVsGhgGraph from '@/charts/gge_vs_ghg';

// wait till everything is loaded
$(function () {
  const ChartOptions = window.ChartOptions;

  // construct the fuel object
  var fuel = {};
  fuel.type = {
    missed: 0,
    dsl: 0,
    gas: 0,
    cng: 0,
    e85: 0,
    bd: 0,
    oth: 0,
    total: {
      petroleum: 0,
      altFuel: 0
    }
  };
  fuel.fySummary = {};
  fuel.byMonth = {};

  var ggeVsGhg = {
    series: [
      {
        name: 'ELEC',
        data: [0,0],
        color: '#0485A8'
      },
      {
        name: 'CNG',
        data: [0,0],
        color: '#07B0DD'
      },
      {
        name: 'BD',
        data: [0,0],
        color: '#0698BF'
      },
      {
        name: 'E85',
        data: [0,0],
        color: '#026C91'
      },
      {
        name: 'DSL',
        data: [0,0],
        color: '#C3C3C3'
      },
      {
        name: 'GAS',
        data: [0,0],
        color: '#A2A1A1'
      },
      {
        name: 'Missed Opps',
        data: [0,0],
        color: '#E37222'
      },
    ]
  };

  var ggeTotal = 0;
  var ghgTotal = 0;

  function parseFuelUsedData (result) {
    $.each(result, function(fCat) {
      if (fCat === 'non_alt_missed_opp_gges') {
        $.each(result[fCat], function(month, types) {
          $.each(types, function(type, val) {
            fuel.type.missed += val;
            fuel.type.total.petroleum += val;
          });
        });
      } else if (fCat === 'non_alt_no_missed_opp_gges') {
        $.each(result[fCat], function(month, types) {
          $.each(types, function(type, val) {
            fuel.type.total.petroleum += val;
            fuel.type[type.toLowerCase()] += val;
          });
        });
      } else if (fCat === 'alt_gges') {
        $.each(result[fCat], function(month, types) {
          $.each(types, function(type, val) {
            fuel.type.total.altFuel += val;
            fuel.type[type.toLowerCase()] += val;
          });
        });
      }
    });
    fuelUsedGraph(fuel.type);
  }

  function parseGhgFuelTypeData (result) {
    $.each(result, function(fCat) {
      if (fCat === 'non_alt_missed_opp_gges') {
        $.each(result[fCat], function(month, types) {
          $.each(types, function(type, val) {
            ggeVsGhg.series[6].data[0] += val;
            ggeTotal += val;
          });
        });
      } else if (fCat === 'non_alt_no_missed_opp_gges') {
        $.each(result[fCat], function(month, types) {
          $.each(types, function(type, val) {
            if (type === 'GAS') {
              ggeVsGhg.series[5].data[0] += val;
              ggeTotal += val;
            } else if (type === 'DSL') {
              ggeVsGhg.series[4].data[0] += val;
              ggeTotal += val;
            }
          });
        });
      } else if (fCat === 'alt_gges') {
        $.each(result[fCat], function(month, types) {
          $.each(types, function(type, val) {
            if (type === 'BD') { 
              ggeVsGhg.series[2].data[0] += val;
              ggeTotal += val;
            } else if (type === 'E85') {
              ggeVsGhg.series[3].data[0] += val;
              ggeTotal += val;
            } else if (type === 'CNG') {
              ggeVsGhg.series[1].data[0] += val;
              ggeTotal += val;
            } else if (type === 'ELEC') {
              ggeVsGhg.series[0].data[0] += val;
              ggeTotal += val;
            }
          });
        });
      } else if (fCat === 'non_alt_missed_opp_ghgs') {
        $.each(result[fCat], function(month, types) {
          $.each(types, function(type, val) {
            ggeVsGhg.series[6].data[1] += val;
            ghgTotal += val;
          });
        });
      } else if (fCat === 'non_alt_no_missed_opp_ghgs') {
        $.each(result[fCat], function(month, types) {
          $.each(types, function(type, val) {
            if (type === 'GAS') {
              ggeVsGhg.series[5].data[1] += val;
              ghgTotal += val;
            } else if (type === 'DSL') {
              ggeVsGhg.series[4].data[1] += val;
              ghgTotal += val;
            }
          });
        });
      } else if (fCat === 'alt_ghgs') {
        $.each(result[fCat], function(month, types) {
          $.each(types, function(type, val) {
            if (type === 'BD') { 
              ggeVsGhg.series[2].data[1] += val;
              ghgTotal += val;
            } else if (type === 'E85') {
              ggeVsGhg.series[3].data[1] += val;
              ghgTotal += val;
            } else if (type === 'CNG') {
              ggeVsGhg.series[1].data[1] += val;
              ghgTotal += val;
            } else if (type === 'ELEC') {
              ggeVsGhg.series[0].data[1] += val;
              ghgTotal += val;
            }
          });
        });
      }
    });

    ggeVsGhg.series.forEach(function (serie) {
      serie.data[0] = (serie.data[0] / ggeTotal) * 100;
      serie.data[1] = (serie.data[1] / ghgTotal) * 100;
    });    

    ggeVsGhgGraph(ggeVsGhg);
  }

  // make the ajax call
  if (ChartOptions.fuelUsedUrl) {
    $.getJSON(ChartOptions.fuelUsedUrl, function (data) {
      parseFuelUsedData(data.result);
      parseGhgFuelTypeData(data.result);
    });
  }
});
