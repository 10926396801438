import Highcharts from '@/lib/highcharts_with_modules';

export default function fuelUsedGraph (type) {
  Highcharts.chart("fuelUsedGraph", {
    tooltip: {
      useHTML: true,
      formatter: function() {
        return "<b>"+ this.point.name + ": </b> "+ Math.round(this.y).toLocaleString() +" GGE";
      }
    },
    title: { 
      text: "Fuel Used by Type" 
    }, 
    plotOptions: { 
      pie: { 
        allowPointSelect: true, 
        cursor: "pointer", 
        showInLegend: true,
        dataLabels: {
          formatter: function () {
            if (this.y !== 0) {
              return "<b>" + this.point.name + "</b><br>" + this.percentage.toFixed(1) + "%";
            }
          }
        }
      } 
    }, 
    series: [ 
      { 
        type: "pie", 
        name: "Individual Fuel Types",
        data: [ 
          { 
            name: "Missed Opps", 
            y: type.missed, 
            color: "#E37222"
          }, 
          { 
            name: "GAS", 
            y: type.gas, 
            color: "#A2A1A1"
          },
          {
            name: "OTH",
            y: type.oth,
            color: "#000000"
          },
          {
            name: "DSL",
            y: type.dsl,
            color: "#C3C3C3"
          },
          {
            name: "E85", 
            y: type.e85, 
            color: "#026C91"
          },
          {
            name: "BD",
            y: type.bd,
            color: "#0698BF"
          },
          {
            name: "CNG",
            y: type.cng,
            color: "#07B0DD"
          }
        ], 
        size: "60%"
      }, 
      { 
        type: "pie", 
        name: "Aggregated Fuel Types",
        dataLabels: {
          distance: -30,
          color: "white",
          formatter: function () {
            if (this.y !== 0) {
              return "<b>" + this.point.name + "</b><br>" + this.percentage.toFixed(1) + "%";
            }
          }
        },
        data: [ 
          { 
            name: "Petroleum", 
            y: type.total.petroleum, 
            color: "#7c7b7b"
          }, 
          { 
            name: "Alt Fuel", 
            y: type.total.altFuel, 
            color: "#005B82"
          }
        ], 
        size: "40%" 
      } 
    ], 
    legend: { 
      // TODO if nobody asks for the legend, clean this up
      enabled: false,
      align: "right",
      verticalAlign: "middle",
      layout: "vertical"
    }, 
    credits: { 
      enabled: false 
    }, 
    exporting: { 
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG', 'separator', 'downloadCSV', 'downloadXLS']
        }
      },
    }
  });
}
