import $ from 'jquery';
import vehicleTypeGraph from '@/charts/vehicle_type';

$(function () {
  const ChartOptions = window.ChartOptions;

  // construct vehicle_type object
  var vehicleTypes = [
    {
      type: 'pie',
      name: 'Individual Vehicle Types',
      data: [],
      size: '60%'
    },
    {
      type: 'pie',
      name: 'Aggregated Vehicle Types',
      dataLabels: {
        distance: -30,
        color: 'white',
        formatter: function () {
          if (this.percentage >= 1) {
            return '<b>' + this.point.name + '</b><br>' + this.percentage.toFixed(0) + '%';
          }
        }
      },
      data: [
        {
          name: 'LD', 
          color: '#98643b',
          y: 0
        },
        {
          name: 'MD', 
          color: '#6a516b',
          y: 0
        },
        {
          name: 'HD',
          color: '#736c4c',
          y: 0
        },
        {
          name: 'Other',
          color: '#5e6a71',
          y: 0
        },
        {
          name: 'LSV',
          color: '#388290',
          y: 0
        }
      ],
      size: '40%'
    }
  ];
  var colorObj = { 
    'LD SUV': '#e59559', 
    'LD Pickup': '#feaf73', 
    'LD Van': '#fecaa1', 
    'Sedan': '#ffdbc1', 
    'NEW': '#ffede0', 
    'MD SUV': '#977499', 
    'MD Van': '#ac90ad', 
    'MD Other': '#c1acc2', 
    'MD Pickup': '#d5c7d6', 
    'Ambulance': '#eae3eb',
    'HD': '#a59a6d',
    'HD Bus': '#b7ae8a', 
    'Other': '#c3c8c8', 
    'LSV': '#74a8b1'
  };

  function parseVehicleTypeData (result) {
    if (!result) {
      return;
    }

    var hds = [],
    mds = [],
    lds = [],
    others = [],
    lsvs = [];

    result.forEach(function (type) {
      // set what Highcharts wants
      type.y = parseInt(type.count);
      type.name = type.vehicle_type_summary_segment;
      type.color = colorObj[type.name];

      // remove unused properties
      delete type.count;
      delete type.vehicle_type_summary_segment;

      if (type.weight_rating == 'LD') {
        lds.push(type);
        vehicleTypes[1].data[0].y += type.y;
      } else if (type.weight_rating == 'MD') {
        mds.push(type);
        vehicleTypes[1].data[1].y += type.y;
      } else if (type.weight_rating == 'HD') {
        hds.push(type);
        vehicleTypes[1].data[2].y += type.y;
      } else if (type.weight_rating == 'Other') {
        others.push(type);
        vehicleTypes[1].data[3].y += type.y;
      } else if (type.weight_rating == 'LSV') {
        lsvs.push(type);
        vehicleTypes[1].data[4].y += type.y;
      }
    });
    
    vehicleTypes[0].data = vehicleTypes[0].data.concat(lds).concat(mds).concat(hds).concat(others).concat(lsvs);

    vehicleTypeGraph(vehicleTypes);
  }

  if (ChartOptions.vehicleTypeUrl) {
    $.getJSON(ChartOptions.vehicleTypeUrl, function (data) {
      parseVehicleTypeData(data.result);
    });
  }
});

