import $ from 'jquery';
import DataTable from 'datatables.net-bs';

$(function () {
  new DataTable('#user-management', {
    searching: true,
    ordering: true,
    order: [4, 'asc']
  });
});
