import $ from 'jquery';
import DataTable from 'datatables.net-bs';

$(function() {
  const TableOptions = window.TableOptions;

  var createdCellHighlight = function(td, cellData, rowData) {
    var zevOpportunityAssessment = rowData.zev_candidate_quality

    if (zevOpportunityAssessment == 'Current ZEV') {
      $(td).addClass('zev-shading-2')
    } else if (zevOpportunityAssessment == 'Good' || zevOpportunityAssessment == 'Great'){
      $(td).addClass('zev-shading-3')
    } else if (zevOpportunityAssessment == 'Mediocre') {
      $(td).addClass('zev-shading-4')
    } else if (zevOpportunityAssessment == 'Challenging') {
      $(td).addClass('zev-shading-5')
    } else if (zevOpportunityAssessment == 'No ZEV Option') {
      $(td).addClass('zev-shading-6')
    }
  }

  var defaultContent = 'N/A';
  var columns = [
    {
      name: 'internal_id',
      data: 'internal_id',
      render: function(data, type, row) {
        return '<a href="/FleetDASH/vehicles/' + row.vehicle_id + '/vat?organization_id=' + TableOptions.organizationId + '&hierarchy_id=' + TableOptions.hierarchyId + '">' + data + '</a>';
      },
      className: 'center-align',
      orderable: false,
    },
    {
      name: 'vehicle_segment',
      data: 'vehicle_segment',
      className: 'center-align',
      orderable: true,
    },
    {
      name: 'fleetdash_fuel_type',
      data: 'fleetdash_fuel_type',
      orderable: false,
      className: 'center-align'
    },
    {
      name: 'total_gges',
      data: 'total_gges',
      orderable: true,
      className: 'justify-right',
      defaultContent: defaultContent,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'gerp',
      data: 'gerp',
      orderable: false,
      className: 'center-align'
    },
    {
      name: 'bev_available',
      data: 'bev_available',
      orderable: false,
      className: 'center-align'
    },
    {
      data: 'phev_available',
      name: 'phev_available',
      orderable: false,
      className: 'center-align'
    },
    {
      name: 'pce',
      data: 'pce',
      orderable: false,
      className: 'center-align'
    },
    {
      name: 'dcfc_availability_transactions_pct',
      data: 'dcfc_availability_transactions_pct',
      orderable: false,
      className: 'justify-right',
      defaultContent: defaultContent,
      render: function (data) {
        return (Math.round(data * 100)) + "%"
      }
    },
    {
      name: 'zev_candidate_quality',
      data: 'zev_candidate_quality',
      orderable: true,
      className: 'center-align',
      createdCell: createdCellHighlight,
      render: function (data) {
        if (data == 'Current ZEV') {
          data = 'Current EV'
        } else if (data == 'No ZEV Option') {
          data = 'No EV Option'
        }
        return data
      }
    },
  ];

  var columnIndexByName = columns.reduce(function(byName, col, index) { byName[col.name] = index; return byName }, {});

  new DataTable('#zev_ld_table', {
    serverSide: true,
    searching: true,
    ordering: true,
    order: [[columnIndexByName.total_gges, 'desc']],
    ajax: function (data, callback) {
      var order_column = data.order[0].column;
      var sort_by = columns[order_column].name;

      $.ajax({
        url: TableOptions.vatLdSummaryUrl,
        data: {
          fiscal_year: TableOptions.vatFiscalYear,
          limit: data.length,
          page: data.start/10 + 1,
          sort_order: data.order[0].dir,
          sort_by: sort_by,
          search: data.search.value,
        },
        success: function (json) {
          var jsonData = [];
          if (json.result) {
            for (var i = 0; i < json.result.length; i++) {
              jsonData.push(json.result[i]);
            }
          }

          var o = {
            recordsTotal: json.metadata.resultset.count,
            recordsFiltered: json.metadata.resultset.count,
            data: jsonData
          };
          callback(o);
        }
      });
    },
    columns: columns,
  });
});
