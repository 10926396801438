import $ from 'jquery';
import he from 'he';
import mapboxgl from 'mapbox-gl';

$(function() {
  const GarageInfo = window.GarageInfo;
  const StationInfo = window.StationInfo;

  var key = "bAhixZGbHpTWHb6SiSwc18GodkFkM5IYH2mMGPzm";
  var mapContainer = document.getElementById("transaction-map");
  var altStationOptions = {
    ev_charging_level: "2, dc_fast",
    ev_connector_type: "all",
    ev_network: "all",
  };

  var altStations = {
    type: "FeatureCollection",
    features: [],
  };

  var garageLocation = {
    type: "FeatureCollection",
    features: [],
  };

  var chargingNetworks = [];

  var stationPoints = {
    type: "FeatureCollection",
    features: [],
  };

  var map;

  getChargingNetworks();

  var selectCharger = $(".select-charger");
  var selectConnector = $(".select-connector");
  var selectNetwork = $(".select-network");

  $(selectCharger).multipleSelect({
    onClick: function () {
     var selectValue = $(selectCharger).multipleSelect("getSelects");
     altStationOptions.ev_charging_level = selectValue.join();
     if(selectValue.length) {
       addAltStationsGeo(false);
     }
    },
    onCheckAll: function() {
      altStationOptions.ev_charging_level = "all";
      addAltStationsGeo(false);
    },
    onUncheckAll:function() {
      altStationOptions.ev_charging_level = "";
      addAltStationsGeo(true);
    },
    placeholder: "Charger Types",
  });

  $(selectCharger).multipleSelect("setSelects", ["2","dc_fast"]);

  $(selectConnector).multipleSelect({
    onClick: function () {
     var selectValue = $(selectConnector).multipleSelect("getSelects");
     altStationOptions.ev_connector_type = selectValue.join();
     if(selectValue.length) {
      addAltStationsGeo(false);
     }
    },
    onCheckAll: function() {
      altStationOptions.ev_connector_type = "all";
      addAltStationsGeo(false);
    },
    onUncheckAll:function() {
      altStationOptions.ev_connector_type = "";
      addAltStationsGeo(true);
    },
    placeholder: "Connector Types",
  });

 $(selectConnector).multipleSelect("checkInvert");

  function addAltStationsGeo(force) {
    $(".loading-spinner").show();
    $.ajax({
      url: 'https://developer.nrel.gov/api/alt-fuel-stations/v1.geojson',
      data: {
        api_key: key,
        fuel_type: 'ELEC',
        response_format: 'compact',
        force_no_results: force,
        ev_charging_level: altStationOptions.ev_charging_level.length ? altStationOptions.ev_charging_level : undefined,
        ev_connector_type: altStationOptions.ev_connector_type.length ? altStationOptions.ev_connector_type : undefined,
        ev_network: altStationOptions.ev_network.length ? altStationOptions.ev_network : undefined,
      },
      success: function(result) {
        altStations = result;
        return renderMap();
      },
    });
  }
  
  function getChargingNetworks() {
    $.ajax({
      url: 'https://developer.nrel.gov/api/alt-fuel-stations/v1/electric-networks.json',
      data: {
        api_key: key,
      },
      success: function(result) {
        chargingNetworks = result;
        return addNetworkSelect();
      },
    });
  }
  
  function addNetworkSelect() {
    if (chargingNetworks.length ) {
      chargingNetworks.forEach(function(network) {
        let networkKey
        if(network.key != 'all' && network.key !== 'Non-Networked') {
          networkKey = network.key;
          selectNetwork.append('<option value="'+ networkKey +'">' + he.escape(network.name) + '</option>');
        }
        else if(network.key === 'Non-Networked') {
          networkKey = network.key;
          selectNetwork.append('<option value="'+ networkKey +'">' + he.escape(network.name) + '</option>');
        }
      });
      $(selectNetwork).multipleSelect({
        onClick: function () {
          var selectValue = $(selectNetwork).multipleSelect("getSelects")
          altStationOptions.ev_network = selectValue.join(); 
          if (selectValue.length) {
            addAltStationsGeo(false);
          }
        },
        onCheckAll: function() {
          altStationOptions.ev_network = "all";
          addAltStationsGeo(false);
        },
        onUncheckAll:function() {
          altStationOptions.ev_network = [];
          addAltStationsGeo(true);
        },
        placeholder: "Charging Networks",
      });
      $(selectNetwork).multipleSelect("checkInvert");
    }
  }

  if (StationInfo.length) {
    var stationReduce = StationInfo.reduce(function(obj, station) {
      function determineLatLong(stn) {
        var manLong = stn.fuel_station.manual_longitude;
        var manLat = stn.fuel_station.manual_latitude;
        if (manLong && manLat) {
          return [manLong, manLat];
        } else {
          return [stn.fuel_station.longitude, stn.fuel_station.latitude]
        }
      };

      if(obj[station.fuel_station_id]) {
        obj[station.fuel_station_id].gge += station.gge_equivalent;
        obj[station.fuel_station_id].transactions +=1;
      } else {
        obj[station.fuel_station_id] = {
          stationId: station.fuel_station_id,
          name: station.fuel_station.name,
          address: station.fuel_station.street_address,
          zip: station.fuel_station.zipcode,
          city: station.fuel_station.city,
          state: station.fuel_station.state,
          fuel_type: station.fuel_type_code.code,
          coordinates: determineLatLong(station),
          gge: station.gge_equivalent,
          transactions: 1,
        };
      }
      return obj;
    }, {});

    Object.keys(stationReduce).forEach(function(building){
      stationPoints.features.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: stationReduce[building].coordinates,
        },
        properties: {
          stationId: stationReduce[building].stationId,
          name: stationReduce[building].name,
          address: stationReduce[building].address,
          zip: stationReduce[building].zip,
          city: stationReduce[building].city,
          state: stationReduce[building].state,
          fuel_type: stationReduce[building].fuel_type,
          gge: Math.round(stationReduce[building].gge),
          transactions: stationReduce[building].transactions,
        }
      });
    });
  }

  if (mapContainer) {
    map = new mapboxgl.Map({
      container: mapContainer,
      style: "https://api.maptiler.com/maps/f67b0db7-842e-43cc-8b96-343382dcaae4/style.json?key=SBgm1WtMJhHxntIq6SNP",
      center: [-47.38737, 49.53033],
      zoom: 2.06,
      // maxZoom: 10,
    });
  }

  if(map) {
    map.on("load", function () {
      setMapBounds();
      map.addControl(new mapboxgl.ScaleControl({
        position: "bottom-right",
        unit: "imperial"
      }));
      map.addControl(new mapboxgl.NavigationControl({
        position: "top-right",
        showZoom: true,
        showCompass: false,
      }));
      if (GarageInfo) {
        garageLocation.features.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [GarageInfo.longitude, GarageInfo.latitude],
          }
        })
      }
      addTransactionData();
    });
  }

  function addTransactionData() {
    map.addSource("station-points-source", {
      type: "geojson",
      data: stationPoints,
      cluster: true,
      clusterMaxZoom: 10,
      clusterMinPoints: 3,
      clusterRadius: 50,
      clusterProperties: {
        "sum": ["+", ["get", "transactions", ["properties"]]],
      }
    });

    map.addLayer({
      id: "station-points-cluster-layer",
      type: "circle",
      source: "station-points-source",
      filter: ["has", "point_count"],
      paint: {
        "circle-stroke-width": 8,
        "circle-stroke-color": "rgba(0, 67, 130, 0.4)",
        "circle-color": "#004382",
        "circle-radius": {
          property: "sum",
          type: "interval",
          stops: [
            [0, 10],
            [5, 20],
            [10, 30],
            [25, 40],
          ],
        },
      },
    });

    map.addLayer({
      id: "cluster-count",
      type: "symbol",
      source: "station-points-source",
      filter: ["has", "point_count"],
      layout: {
        "text-field": ["get", "sum"],
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 12,
        "text-allow-overlap": true,
      },
      paint: {
        "text-color": "#ffffff",
      },
    });

    map.addLayer({
      id: "unclustered-point",
      type: "circle",
      source: "station-points-source",
      filter: ["!", ["has", "point_count"]],
      paint: {
        "circle-radius": 6,
        "circle-stroke-width": 1,
        "circle-stroke-color": "#fff",
        "circle-color": "#004382",
      },
    });

    map.addSource("alt-stations", {
      type: "geojson",
      data: altStations,
    });

    map.addLayer({
      id: "alt-stations-layer",
      type: "circle",
      source: "alt-stations",
      paint: {
        "circle-radius": 6,
        "circle-stroke-width": 1,
        "circle-stroke-color": "#7c7b7b",
        "circle-color": "#2db262",
      }

    }, "station-points-cluster-layer");

    map.addSource("garage-location", {
      type: "geojson",
      data: garageLocation,
    });

    map.addLayer({
      id: "garage-point-layer",
      type: "circle",
      source: "garage-location",
      paint: {
        "circle-radius": 6,
        "circle-color": "#E37222",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#ffffff",
      }
    }, "cluster-count");

    if (altStations.features.length) {
      $(".loading-spinner").hide();
    }
  }

  function setMapBounds() {
    var bounds = new mapboxgl.LngLatBounds();
    if(stationPoints.features.length > 1) {
      stationPoints.features.forEach(function(feature) {
        bounds.extend(feature.geometry.coordinates);
      });
      map.fitBounds(bounds, {
        padding: 20,
      });
    } else if (stationPoints.features.length === 1) {
      map.flyTo({
        center: stationPoints.features[0].geometry.coordinates,
        zoom: 10,
      });
    }
  }

  function renderMap() {
    if (map.getSource("alt-stations")) {
      map.getSource("alt-stations").setData(altStations);
      $(".loading-spinner").hide();
    }
  }

  if (map) {
    function renderUnclusteredPoint(layer) {
      var coordinates = layer[0].geometry.coordinates.slice();
      var popupContent = createPopup('transaction', layer[0].properties);
      
      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(popupContent)
        .addTo(map);
    }
    
    function createPopup(popupType, info) {
      var content = popupType === 'altFuel' ? info.alt_fuel_station : info;
      var popupContent = '';
      if (content) {
        var stationName = popupType === 'altFuel' ? content.station_name : content.name;
        var stationAddress = popupType === 'altFuel' ? content.street_address : content.address;
        popupContent += "<div class='transaction-popup'><p><strong>"+ he.escape(stationName) + "</strong></p>"
        popupContent += "<p>" + he.escape(stationAddress) +"</p>"
        popupContent += "<p>" + he.escape(content.city) + " " + he.escape(content.state) + ", " + he.escape(content.zip) + "</p>"
          if (popupType === "transaction") {
            popupContent+= "<p><strong>Fuel purchased: </strong>" + he.escape(content.fuel_type) + "</p>"
            popupContent+= "<p><strong>GGEs purchased: </strong>" + he.escape(content.gge.toString()) + "</p>"
            popupContent+= "<p><strong>Transactions at this station: </strong>" + he.escape(content.transactions.toString()) + "</p>"
          }
          if (popupType === 'altFuel') {
            var evLevel = formatEvLevels(content.ev_level1_evse_num, content.ev_level2_evse_num, content.ev_dc_fast_num)
            popupContent+= "<strong>Charger Types: </strong>" + he.escape(evLevel) + "</p>"
            popupContent+= "<strong>Charging Network: </strong>" + he.escape(content.ev_network) + "</p>"
            popupContent+= "<p><a href='https://www.afdc.energy.gov/stations/#/station/" + he.escape(content.id.toString()) + "' target='_blank' rel='noopener noreferrer'>Station Details</a></p>"
          }
        popupContent += "</div>"
      }
      return popupContent; 
    } 

    function viewAltStationsPopup(features) {
      var coordinates = features[0].geometry.coordinates.slice();
      var stationId = features[0].properties.id;
      $(".loading-spinner").show();
      $.ajax({
        url: 'https://developer.nrel.gov/api/alt-fuel-stations/v1/'+stationId+'.json',
        data: {
          api_key: key
        },
        success: function(result) {
          $(".loading-spinner").hide();
          return renderAltPopup(coordinates, result)
        }
      });
    }

    function renderAltPopup(coordinates, stationInfo) {
      var popupContent = createPopup('altFuel', stationInfo)
      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(popupContent)
        .addTo(map);
    }

    function formatEvLevels(level1, level2, dcFast) {    
      var stationLevels = null;
      if (level1 || level2 || dcFast) {
        var levels = [];
        if (level1 && level1 !== "null" ) { levels.push("Level 1"); }
        if (level2 && level2 !== "null") { levels.push("Level 2"); }
        if (dcFast && dcFast !== "null") { levels.push("DC Fast"); }
        stationLevels = levels.join(", ");
      }
      return stationLevels;
    }

    function zoomToCluster(features) {
      var clusterId = features[0].id;
      map.getSource("station-points-source").getClusterExpansionZoom(clusterId, function (err, zoom) {
        if (err) return;
          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
          });
      });
    }

    map.on("click", function(e) {
      var stationPointsLayer = map.queryRenderedFeatures(e.point, { layers: ["station-points-cluster-layer"] });
      var altStationsLayer = map.queryRenderedFeatures(e.point, {layers: ["alt-stations-layer"] });
      var unclusteredPoint = map.queryRenderedFeatures(e.point, {layers: ["unclustered-point"] });
      // This sets which map popup will appear so that mutliple popups do not appear at once when stations are almost at the same coordinates
      if (stationPointsLayer.length && altStationsLayer.length && !unclusteredPoint.length) {
        zoomToCluster(stationPointsLayer);
      } else if (!stationPointsLayer.length && !altStationsLayer.length && unclusteredPoint.length) {
        renderUnclusteredPoint(unclusteredPoint);
      } else if (unclusteredPoint.length && altStationsLayer.length && !stationPointsLayer.length ) {
        renderUnclusteredPoint(unclusteredPoint);
      } else if ((!unclusteredPoint.length && !altStationsLayer.length) && stationPointsLayer.length) {
        zoomToCluster(stationPointsLayer);
      } else if ((!stationPointsLayer.length && !unclusteredPoint.length ) && altStationsLayer.length) {
        viewAltStationsPopup(altStationsLayer);
      }
    });

    map.on("mousemove", function(e) {
      var pointInfo = map.queryRenderedFeatures(e.point);
      if (pointInfo.length) {
        if(pointInfo[0].layer.id === "alt-stations-layer" || pointInfo[0].layer.id === "station-points-cluster-layer" || pointInfo[0].layer.id === "cluster-count" || pointInfo[0].layer.id === "unclustered-point")  {
          map.getCanvas().style.cursor = "pointer";
        } else {
          map.getCanvas().style.cursor = "";
        }
      }
    });
  }
});
