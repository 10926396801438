import $ from 'jquery';
import monthlyTotalGraph from '@/charts/monthly_total';
import monthlyTotalGhgGraph from '@/charts/monthly_total_ghg';

$(function () {
  const ChartOptions = window.ChartOptions;

  var data = {
    'E85': [], // #8085e9
    'BD': [], // blue
    'CNG': [], // green
    'ELEC': [], // orange
    'HY': [], // yellow
    'LNG': [], // brown
    'LPG': [], // pink
    'DSL': [], // purple
    'GAS': [], // #f7a35c
    'OTH': [],
    'Missed Opps': [], // red
  };

  var ghgData = {
    'E85': [], // #8085e9
    'BD': [], // blue
    'CNG': [], // green
    'ELEC': [], // orange
    'HY': [], // yellow
    'LNG': [], // brown
    'LPG': [], // pink
    'DSL': [], // purple
    'GAS': [], // #f7a35c
    'OTH': [],
    "Avoidable GHGs": [], // red
  }

  var colorObj = { 
    E85: '#026C91',
    BD: '#0698BF',
    CNG: '#07B0DD',
    ELEC: '#0485A8',
    HY: '#01516D',
    LNG: '#034759',
    LPG: '#046984',
    DSL: '#C3C3C3',
    GAS: '#A2A1A1',
    OTH: '#000000',
    Missed: '#E37222'
  };

  function parseMonthlyTotalData (result) {
    var processedData = {
      data: [],
      months: []
    };

    var processedGhgData = {
      data: [],
      months: []
    };

    $.each(result, function (fCat) {
      if (fCat === 'non_alt_missed_opp_gges') {
        $.each(result[fCat], function(month) {
          if (processedData.months.indexOf(month) === -1) {
            processedData.months.push(month);
          }
        });
      } else if (fCat === 'non_alt_missed_opp_ghgs') {
        $.each(result[fCat], function(month) {
          if (processedGhgData.months.indexOf(month) === -1) {
            processedGhgData.months.push(month);
          }
        });
      } else if (fCat === 'avoidable_ghgs') {
        $.each(result[fCat], function(month) {
          if (processedGhgData.months.indexOf(month) === -1) {
            processedGhgData.months.push(month);
          }
        });
      } else if (fCat === 'non_alt_no_missed_opp_gges') {
        $.each(result[fCat], function(month) {
          if (processedData.months.indexOf(month) === -1) {
            processedData.months.push(month);
          }
        });
      } else if (fCat === 'non_alt_no_missed_opp_ghgs') {
        $.each(result[fCat], function(month) {
          if (processedGhgData.months.indexOf(month) === -1) {
            processedGhgData.months.push(month);
          }
        });
      } else if (fCat === 'alt_gges') {
        $.each(result[fCat], function(month) {
          if (processedData.months.indexOf(month) === -1) {
            processedData.months.push(month);
          }
        });
      } else if (fCat === 'alt_ghgs') {
        $.each(result[fCat], function(month) {
          if (processedGhgData.months.indexOf(month) === -1) {
            processedGhgData.months.push(month);
          }
        });
      }
    });

    processedData.months.sort(function (a, b) {
      return parseInt(a.split('-').join('')) - parseInt(b.split('-').join(''));
    });
    processedGhgData.months.sort(function (a, b) {
      return parseInt(a.split('-').join('')) - parseInt(b.split('-').join(''));
    });

    $.each(result, function(fCat) {
      var exists = false;

      if (fCat === 'non_alt_missed_opp_gges') {
        processedData.months.forEach(function (month) {
          var splitMonth = month.split('-');
          var utcTime = Date.UTC(splitMonth[0], splitMonth[1] - 1, splitMonth[2]);
          data['Missed Opps'].push([utcTime, 0]);
          if (!$.isEmptyObject(result[fCat][month])) {
            $.each(result[fCat][month], function(type, val) {
              data['Missed Opps'][data['Missed Opps'].length - 1][1] += val;
              data[type].forEach(function (datum) {
                if (datum.indexOf(utcTime) != -1) {
                  exists = true;
                  datum[1] += val;
                }
              });

              if (exists === false) {
                data[type].push([utcTime, val]);
              }
            });
          }
        });
      } else if (fCat === 'non_alt_missed_opp_ghgs') {
        processedData.months.forEach(function (month) {
          var splitMonth = month.split('-');
          var utcTime = Date.UTC(splitMonth[0], splitMonth[1] - 1, splitMonth[2]);
          if (!$.isEmptyObject(result[fCat][month])) {
            $.each(result[fCat][month], function(type, val) {
              ghgData[type].forEach(function (datum) {
                if (datum.indexOf(utcTime) != -1) {
                  exists = true;
                  datum[1] += val;
                }
              });

              if (exists === false) {
                ghgData[type].push([utcTime, val]);
              }
            });
          }
        });
      } else if (fCat === 'avoidable_ghgs') {
        processedGhgData.months.forEach(function (month) {
          var splitMonth = month.split('-');
          var utcTime = Date.UTC(splitMonth[0], splitMonth[1] - 1, splitMonth[2]);
          ghgData["Avoidable GHGs"].push([utcTime, 0]);
          if (!$.isEmptyObject(result[fCat][month])) {
            $.each(result[fCat][month], function(type, val) {
              ghgData["Avoidable GHGs"][ghgData["Avoidable GHGs"].length - 1][1] += val;
            });
          }
        });
      } else if (fCat === 'non_alt_no_missed_opp_gges') {
        processedData.months.forEach(function (month) {
          var splitMonth = month.split('-');
          var utcTime = Date.UTC(splitMonth[0], splitMonth[1] - 1, splitMonth[2]);
          if (!$.isEmptyObject(result[fCat][month])) {
            $.each(result[fCat][month], function(type, val) {
              data[type].forEach(function (datum) {
                if (datum.indexOf(utcTime) != -1) {
                  exists = true;
                  datum[1] += val;
                }
              });

              if (exists == false) {
                data[type].push([utcTime, val]);
              }
            });
          }
        });
      } else if (fCat === 'non_alt_no_missed_opp_ghgs') {
        processedGhgData.months.forEach(function (month) {
          var splitMonth = month.split('-');
          var utcTime = Date.UTC(splitMonth[0], splitMonth[1] - 1, splitMonth[2]);
          if (!$.isEmptyObject(result[fCat][month])) {
            $.each(result[fCat][month], function(type, val) {
              ghgData[type].forEach(function (datum) {
                if (datum.indexOf(utcTime) != -1) {
                  exists = true;
                  datum[1] += val;
                }
              });

              if (exists == false) {
                ghgData[type].push([utcTime, val]);
              }
            });
          }
        });
      } else if (fCat === 'alt_gges') {
        processedData.months.forEach(function (month) {
          if (!$.isEmptyObject(result[fCat][month])) {
            $.each(result[fCat][month], function(type, val) {
              if (type !== 'NONE' && data[type]) {
                var splitMonth = month.split('-');
                var utcTime = Date.UTC(splitMonth[0], splitMonth[1] - 1, splitMonth[2]);
                data[type].push([utcTime, val]);
              }
            });
          }
        });
      } else if (fCat === 'alt_ghgs') {
        processedGhgData.months.forEach(function (month) {
          if (!$.isEmptyObject(result[fCat][month])) {
            $.each(result[fCat][month], function(type, val) {
              if (type !== 'NONE' && data[type]) {
                var splitMonth = month.split('-');
                var utcTime = Date.UTC(splitMonth[0], splitMonth[1] - 1, splitMonth[2]);
                ghgData[type].push([utcTime, val]);
              }
            });
          }
        });
      }
    });

    // pop off last items of each array if they're all 0's
    var removeLast = true;
    var removeSecondToLast = true;
    $.each(data, function(name, values) {
      if (values[values.length - 1][1] !== 0) {
        removeLast = false;
      } 
      if (values[values.length - 2][1] !== 0) {
        removeSecondToLast = false;
      } 
    });
    if (removeLast === true) {
      $.each(data, function(name, values) {
        values.pop();
      });
    }
    if (removeSecondToLast === true) {
      $.each(data, function(name, values) {
        values.pop();
      });
    }

    // pop off last items of each array if they're all 0's AND first values if the array is NaN
    removeLast = true;
    removeSecondToLast = true;
    if (ghgData['Avoidable GHGs'].length !== 0 && ghgData.BD.length !== 0 && ghgData.CNG.length !== 0 && ghgData.DSL.length !== 0 && ghgData.E85.length !== 0 && ghgData.ELEC.length !== 0 && ghgData.GAS.length !== 0 && ghgData.HY.length !== 0 && ghgData.LNG.length !== 0 && ghgData.LPG.length !== 0 && ghgData.OTH.length !== 0) {
      $.each(ghgData, function(name, values) {
        if (values[values.length - 1][1] !== 0) {
          removeLast = false;
        } 
        if (values[values.length - 2][1] !== 0) {
          removeSecondToLast = false;
        } 

        // FIXME: I'm really not sure why this has to happen... So for now I'll fix the symptoms, not the cause
        if (values[0] == undefined) {
          values.shift();
        }
      });
      if (removeLast === true) {
        $.each(ghgData, function(name, values) {
          values.pop();
        });
      }
      if (removeSecondToLast === true) {
        $.each(ghgData, function(name, values) {
          values.pop();
        });
      }
    }

    // build highcharts series
    $.each(data, function(name, values) {
      var actualVals = values.filter(function (val) {
        return val[1] > 0;
      });
      if (name === 'Missed Opps') {
        processedData.data.push({
          name: name, 
          stack: 'fuels', 
          type: 'spline', 
          color: '#E37222',
          data: values
        });
      } else if (name !== 'months' && !$.isEmptyObject(values) && actualVals.length > 0) {
        processedData.data.push({
          name: name, 
          type: 'column', 
          stack: 'fuels', 
          color: colorObj[name], 
          data: values
        });
      }
    });

    // build highcharts series for ghg
    $.each(ghgData, function(name, values) {
      var actualVals = values.filter(function (val) {
        return val[1] > 0;
      });
      if (name === "Avoidable GHGs") {
        processedGhgData.data.push({
          name: name, 
          stack: 'fuels', 
          type: 'spline', 
          color: '#E37222',
          data: values
        });
      } else if (name !== 'months' && !$.isEmptyObject(values) && actualVals.length > 0) {
        processedGhgData.data.push({
          name: name, 
          type: 'column', 
          stack: 'fuels', 
          color: colorObj[name], 
          data: values
        });
      }
    });

    processedData.months.forEach(function (month, i) {
      var splitMonth = month.split('-');
      processedData.months[i] = new Date(splitMonth[0], splitMonth[1], splitMonth[2]);
    });
    processedGhgData.months.forEach(function (month, i) {
      var splitMonth = month.split('-');
      processedGhgData.months[i] = new Date(splitMonth[0], splitMonth[1], splitMonth[2]);
    });

    monthlyTotalGraph(processedData);
    monthlyTotalGhgGraph(processedGhgData);
  }

  // make the ajax call
  if (ChartOptions.monthlyTotalUrl) {
    $.getJSON(ChartOptions.monthlyTotalUrl, function (data) {
      parseMonthlyTotalData(data.result);
    });
  }
});
