import $ from 'jquery';
import DataTable from 'datatables.net-bs';

$(function () {
  // used for a variety of tables mostly on the admin side
  new DataTable('.datatable', {
    destroy: true
  });
});

