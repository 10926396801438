import $ from 'jquery';
import fuelTypeGraph from '@/charts/fuel_type';

$(function () {
  const ChartOptions = window.ChartOptions;

  // construct fuel_type object
  var fuelTypes = [
    {
      type: 'pie',
      name: 'Individual Types',
      data: [],
      size: '60%'
    },
    {
      type: 'pie',
      name: 'Aggregations',
      dataLabels: {
        distance: -30,
        color: 'white',
        formatter: function () {
          if (this.percentage >= 1) {
            return '<b>' + this.point.name + '</b><br>' + this.percentage.toFixed(0) + '%';
          }
        }
      },
      data: [
        {
          name: 'Petroleum', 
          color: '#7c7b7b',
          y: 0
        },
        {
          name: 'Alt Fuel', 
          color: '#005B82',
          y: 0
        }
      ],
      size: '40%'
    }
  ];
  var colorObj = { 
    'Petroleum': '#7c7b7b',
    'DSL': '#C3C3C3',
    'GAS': '#A2A1A1',
    'Alt Fuel': '#005B82',
    'BD': '#C3C3C3',
    'FFV': '#026C91',
    'NF': '#0698BF',
    'HEV': '#07B0DD',
    'CNG': '#046984',
    'PHEV': '#01516D',
    'PEV': '#0485A8'
  };

  function parseFuelTypeData (result) {
    result.forEach(function (type) {
      // set what Highcharts wants
      type.y = parseInt(type.count);
      type.name = type.fleetdash_fuel_type;
      type.color = colorObj[type.name];
      // remove unused properties
      delete type.count;
      delete type.fleetdash_fuel_type;

      if (type.afv === true) {
        fuelTypes[0].data.push(type);
        fuelTypes[1].data[1].y += type.y;
      } else {
        fuelTypes[0].data.unshift(type);
        fuelTypes[1].data[0].y += type.y;
      }

      delete type.afv;
    });

    fuelTypeGraph(fuelTypes);
  }

  function parseExecFuelTypeData (result) {
    if (!result) {
      return;
    }

    result.forEach(function (type) {
      // set what Highcharts wants
      type.y = parseInt(type.count);
      type.name = type.fleetdash_fuel_type;
      type.color = colorObj[type.name];
      // remove unused properties
      delete type.count;
      delete type.fleetdash_fuel_type;

      if (type.afv === true) {
        fuelTypes[0].data.push(type);
        fuelTypes[1].data[1].y += type.y;
      } else {
        fuelTypes[0].data.unshift(type);
        fuelTypes[1].data[0].y += type.y;
      }

      delete type.afv;
    });

    fuelTypeGraph(fuelTypes);
  }

  if (ChartOptions.fuelTypeUrl) {
    $.getJSON(ChartOptions.fuelTypeUrl, function (data) {
      if (data.length !== undefined) {
        parseFuelTypeData(data.result);
      } else {
        parseExecFuelTypeData(data.result);
      }
    });
  }
});

