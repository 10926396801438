import $ from 'jquery';
import DataTable from 'datatables.net-bs';

$(function () {
  // for the child nodes table
  new DataTable('#child_nodes', {
    searching: true,
    ordering: true,
    order: [6, 'desc'],
    'columnDefs': [
      { className: 'justify-right', 'targets': [1, 2, 3, 4, 5, 6, 7, 8, 9] }
    ]
  });
});
