import $ from 'jquery';

$(function () {
  const TableOptions = window.TableOptions;

  var $searchAgency = $('#search_fleet_name'),
  $agencyOptions = $searchAgency.find('option'),
  $searchRegional = $('#search_regional');

  var $orgNode = $('#org-node').clone();  
  $('#org-node').remove();
  $searchAgency.parent().parent().after($orgNode);
  // need to find this again after the move
  var $orgChildren = $('#org-children');

  $searchAgency.change(function () {
    var $val = $(this).val();

    if ($val !== '') {
      $searchRegional.prop('disabled', true);

      var url = '/FleetDASH/api/v1/organizations/' +
        TableOptions.organizationId + 
        '/hierarchies/' + 
        TableOptions.hierarchyId + 
        '/nodes/' + 
        $val + 
        '/leaf_nodes';

      $.getJSON(url, function (data) {
        $orgChildren.empty();
        $orgChildren.append('<option value=""></option>');

        data.result.forEach(function (node) {
          var node_name = (node.alt_name === null || node.alt_name === '') ? node.name : node.alt_name;
          var option = '<option value="' + node.id + '">' + node_name + '</option>';

          $orgChildren.append(option);
        });
      });
    } else {
      $('#search_regional').prop('disabled', false);
      $orgChildren.empty();
      $orgChildren.append('<option value="">First, choose and agency...</option>');
    }
  });

  // pre-select agency if there's only one
  if ($agencyOptions.length == 2) {
    $agencyOptions.each(function () {
      if ($(this).html() === '') {
        $(this).remove();
      }
    });
    $agencyOptions.trigger('change');
  }

  $searchRegional.change(function () {
    if ($(this).val() === '') {
      $('#hierarchy_id').val($(this).data(''));
      $searchAgency.prop('disabled', false);
      $orgChildren.prop('disabled', false);
    } else {
      var val = $(this).data('hierarchyid');
      $('#hierarchy_id').val(val);
      $searchAgency.prop('disabled', true);
      $orgChildren.prop('disabled', true);
    }
  });

  $('#searchSubmit').click(function () {
    $(this).parents('form').first().submit();
  });
});
