import $ from 'jquery';
import DataTable from 'datatables.net-bs';

$(function() {
  const TableOptions = window.TableOptions;

  var defaultContent = 'N/A';
  var columns = [
    {
      name: 'node_name',
      data: function (row) {
        var name;
        if (row.vehicle_class === "T") {
          name = "Total";
        } else if (row.vehicle_class === "LD") {
          name = "Light-Duty";
        } else if (row.vehicle_class === "HD") {
          name = "Medium and Heavy-Duty";
        }

        return name;
      },
      orderable: false,
      className: 'heavy-border-right',
    },
    {
      name: 'total_vehicle_count',
      data: 'total_vehicle_count',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'ev_replacement_counts',
      data: 'ev_replacement_counts',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'phev_replacement_counts',
      data: 'phev_replacement_counts',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'hev_replacement_counts',
      data: 'hev_replacement_counts',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'e85_replacement_counts',
      data: 'e85_replacement_counts',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'b20_replacement_counts',
      data: 'b20_replacement_counts',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'cng_replacement_counts',
      data: 'cng_replacement_counts',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'lpg_replacement_counts',
      data: 'lpg_replacement_counts',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'e85_availability_gges_pct',
      data: 'e85_availability_gges_pct',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0, null, '%'),
    },
    {
      name: 'b20_availability_gges_pct',
      data: 'b20_availability_gges_pct',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0, null, '%'),
    },
    {
      name: 'cng_availability_gges_pct',
      data: 'cng_availability_gges_pct',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0, null, '%'),
    },
    {
      name: 'lpg_availability_gges_pct',
      data: 'lpg_availability_gges_pct',
      className: 'justify-right heavy-border-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0, null, '%'),
    },
  ];
  var columnIndexByName = columns.reduce(function(byName, col, index) { byName[col.name] = index; return byName }, {});

  new DataTable('#vat_node_summary_table', {
    serverSide: true,
    ordering: false,
    paging: false,
    info: false,
    order: [[columnIndexByName.node_name, 'desc']],
    ajax: function (data, callback) {
      $.ajax({
        url: TableOptions.vatNodeSummaryUrl,
        data: {
          fiscal_year: TableOptions.vatFiscalYear,
        },
        success: function (json) {
          var jsonData = [];
          if (json.result) {
            for (var i = 0; i < json.result.length; i++) {
              jsonData.push(json.result[i]);
            }
          }

          var o = {
            recordsTotal: json.metadata.resultset.count,
            recordsFiltered: json.metadata.resultset.count,
            data: jsonData
          };
          callback(o);
        }
      });
    },
    columns: columns,
  });
});
