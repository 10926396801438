import $ from 'jquery';
import DataTable from 'datatables.net-bs';

$(function () {
  const TableOptions = window.TableOptions;
  const getQueryString = window.getQueryString;

  // used for a variety of tables mostly on the admin side
  new DataTable('#searches_table', {
    serverSide: true,
    'ajax': function (data, callback) {
      $.ajax({
        url: TableOptions.searchUrl,
        data: {
          limit: data.length,
          page: data.start/10 + 1
        },
        success: function (json) {
          if (json.result) {
            var data = [];
            for ( var i=0; i<json.result.length; i++ ) {
              data.push(json.result[i]);
            }

            var o = {
              recordsTotal: json.metadata.resultset.count,
              recordsFiltered: json.metadata.resultset.count,
              data: data
            };

            callback(o);
          } else {
            $('#searches_table_processing').remove();
            $('#searches_table').after('<h1 style="color: red;">Your search yielded no results.</h1>');
            $('#searches_table').remove();
          }
        }
      });
    },
    'columns': [
      {'data': function (row) {

          return '<a href="/FleetDASH/vehicles/' + row.id + '?organization_id=' + TableOptions.organizationId + '&hierarchy_id=' + TableOptions.hierarchyId + '&tab=' + getQueryString().tab +'">' + row.internal_id + '</a>';
      }},
      {'data': 'vin'},
      {'data': 'fuel_type'},
      {'data': 'make'},
      {'data': 'model'}
    ]
  });
});

