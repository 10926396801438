import Highcharts from '@/lib/highcharts_with_modules';

export default function ggeVsGhgGraph (data) {
  Highcharts.chart("ggeVsGhgGraph", {
    chart: {
      type: "column"
    },
    title: { 
      text: "Comparison of GGE and GHG" 
    }, 
    xAxis: {
      categories: ['GGEs', 'GHG (kg CO2e)']
    },
    yAxis: {
      title: {
        text: ""
      },
      labels: {
        format: "{value}%",
      },
      max: 100
    },
    tooltip: {
      useHTML: true,
      formatter: function() {
        return "<b>"+ this.series.name + ": </b> "+ this.y.toFixed(1).toLocaleString() + "%";
      }
    },
    plotOptions: { 
      column: { 
        // allowPointSelect: true, 
        cursor: "pointer", 
        stacking: 'normal',
        showInLegend: true,
        dataLabels: {
          enabled: false,
        }
      } 
    }, 
    series: data.series, 
    legend: { 
      enabled: true,
      align: "right",
      verticalAlign: "middle",
      layout: "vertical"
    }, 
    credits: { 
      enabled: false 
    }, 
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG', 'separator', 'downloadCSV', 'downloadXLS']
        }
      },
    } 
  });
}
