import $ from 'jquery';
import DataTable from 'datatables.net-bs';

$(function () {
  // defaults for both dataTables
  Object.assign(DataTable.defaults, {
    searching: false,
    ordering: false,
    lengthChange: false,
    processing: true
  });
});

// used by several of the tables
window.tablePercentage = function tablePercentage (row, numerator) {
  var dnom = row.non_alt_no_missed_opp_gges + row.alt_gges + row.non_alt_missed_opp_gges;

  var percent = ((numerator)/(dnom))*100;

  var roundedString = Math.round(percent).toLocaleString();

  if (isNaN(roundedString)) {
    return '';
  } else {
    return roundedString;
  }
}
