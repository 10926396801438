import $ from 'jquery';
import DataTable from 'datatables.net-bs';

$(function () {
  const TableOptions = window.TableOptions;

  var $nodesTable = $('#campaign-nodes-table'),
  $campaignsTable = $('#campaigns-table'),
  $campaignNode = $('#campaign-node'),
  $campaignOrg = $('#campaign-org'),
  previousNodes = [{child: false, name: 'All Organizations'}],
  $usersTable = $('#campaign-users-table'),
  usersStarted = false;
  let usersDataTable;

  const nodesDataTable = new DataTable($nodesTable[0], {
    searching: true,
    // ordering: true,
    'ajax': {
      url: TableOptions.nodesUrl
    },
    'columns': [
      {'data': function (row) {
        var name = row.alt_name ? row.alt_name : row.name,
        rowId = row.id,
        child = typeof row.parent_node_id !== 'undefined';
        if (TableOptions.isCampaignEdit) {
          return name;
        } else {
          if (row.level_type) {
            return '<a href="#" data-child="' + child + '" data-node-id="' + rowId + '" class="node_link">' + name + '</a>';
          } else {
            return '<a href="#" data-child="' + child + '" data-org-id="' + rowId + '" class="node_link">' + name + '</a>';
          }
        }
      }},
      {'data': function (row) {
        return row.level_type ? row.level_type : 'organization';
      }}
    ]
  });

  function initUsersTable (nodeId) {
    usersDataTable = new DataTable($usersTable[0], {
      searching: true,
      paging: false,
      'ajax': {
        url: TableOptions.isCampaignEdit ? TableOptions.usersUrl : TableOptions.usersUrl + '&child=false&node_id=' + nodeId
      },
      'columns': [
        {'data': function (row) {
          var selected = '';
          if (TableOptions.campaignUsers && TableOptions.campaignUsers.indexOf(row.id) !== -1) {
            selected = ' checked="true"';
          }
          return '<input name="email_campaign[user_ids][]" value="' + row.id + '" type="checkbox"' + selected + '>';
        }},
        {'data': function (row) {
          if (row.first_name !== null && row.last_name !== null) {
            return row.first_name + ' ' + row.last_name;
          } else {
            return '';
          }
        }},
        {'data': function (row) {
          return row.email;
        }}
      ]
    });
  }

  if (TableOptions.isCampaignEdit === true) {
    initUsersTable(TableOptions.editNodeId);
  }

  $nodesTable.on('click', 'a', function (e) {
    e.preventDefault();

    var nodeId = $(this).data('node-id'),
    orgId = $(this).data('org-id'),
    child = $(this).data('child');

    previousNodes.push({name: $(this).html(), nodeId: nodeId, orgId: orgId, child: child});
    drawCrumbs();

    $campaignNode.val(nodeId);
    $campaignOrg.val(orgId);

    var id = nodeId === undefined ? orgId : nodeId;
    var url = TableOptions.nodesUrl + '?node_id=' + id + '&child=' + child;

    nodesDataTable.ajax.url(url).load();

    updateUsersTable(id, child, true);
  });

  function drawCrumbs () {
    var $nodeCrumbs = $('#node-crumbs');

    $nodeCrumbs.empty();

    previousNodes.forEach(function (node, i) {
      var name = node.name;

      if (i > 0) {
        $nodeCrumbs.append(' > ');
      }

      if (previousNodes.length == i + 1) {
        $nodeCrumbs.append(name);
      } else if (name == 'All Organizations') {
        $nodeCrumbs.append('<a class="campaign-crumb" href="#">' + name + '</a>');
      } else if (node.nodeId) {
        $nodeCrumbs.append('<a class="campaign-crumb" href="#" data-node-id="' + node.nodeId + '" data-child="' + node.child + '">' + name + '</a>');
      } else {
        $nodeCrumbs.append('<a class="campaign-crumb" href="#" data-org-id="' + node.orgId + '" data-child="' + node.child + '">' + name + '</a>');
      }
    });
  }

  drawCrumbs();

  function updateUsersTable (nodeId, child, getUsers) {
    if (!usersStarted) {
      usersStarted = true;
      initUsersTable(nodeId);
    } else if (getUsers === true) {
      const url = TableOptions.usersUrl + '&node_id=' + nodeId + '&child=' + child;
      usersDataTable.ajax.url(url).load();
    } else {
      usersDataTable.clear().draw();
    }
  }

  $('#node-crumbs').on('click', 'a', function (e) {
    e.preventDefault();

    var nodeId = $(this).data('node-id'),
    orgId = $(this).data('org-id'),
    child = $(this).data('child'),
    id;

    var index = previousNodes.indexOf({name: $(this).html(), nodeId: nodeId, orgId: orgId});
    previousNodes.splice(index, previousNodes.length);
    drawCrumbs();

    $campaignNode.val(nodeId);
    $campaignNode.val(orgId);

    var url = TableOptions.nodesUrl;

    if (nodeId === undefined && orgId === undefined) {
      // No-op
    } else if (nodeId === undefined) {
      id = orgId;
      url += '?node_id=' + orgId + '&child=' + child;
    } else {
      id = nodeId;
      url += '?node_id=' + nodeId + '&child=' + child;
    }

    nodesDataTable.ajax.url(url).load();

    if ($(this).html() !== 'All Organizations') {
      updateUsersTable(id, child, true);
    } else {
      $campaignNode.val('');
      updateUsersTable(id, child, false);
    }

  });

  new DataTable($campaignsTable[0], {
    searching: true,
    ordering: true,
    order: [[ 0, 'asc' ]]
  });
});
