import $ from 'jquery';
import { Spinner } from 'spin.js';

$(function () {
  $('.send-campaign-link').click(function (e) {
    e.preventDefault();

    var opts = {
      lines: 13, // The number of lines to draw
      length: 20, // The length of each line
      width: 10, // The line thickness
      radius: 30, // The radius of the inner circle
      corners: 1, // Corner roundness (0..1)
      rotate: 0, // The rotation offset
      direction: 1, // 1: clockwise, -1: counterclockwise
      color: '#000', // #rgb or #rrggbb or array of colors
      speed: 1, // Rounds per second
      trail: 60, // Afterglow percentage
      shadow: true, // Whether to render a shadow
      hwaccel: false, // Whether to use hardware acceleration
      className: 'spinner', // The CSS class to assign to the spinner
      zIndex: 2e9, // The z-index (defaults to 2000000000)
      top: '50%', // Top position relative to parent
      left: '50%' // Left position relative to parent
    };

    if (window.confirm('Are you sure you want to send out this campaign email?')) {
      $('body').append('<div class="grey-overlay"><div id="sending-campaign">Please be patient while we send out the email campaign.</div><div id="campaign-spinner"></div></div>');

      var target = document.getElementById('campaign-spinner');
      var spinner = new Spinner(opts).spin(target);

      var campaignUrl = $(this).attr('href');

      // set an indefinite timeout, because this can take a VERY long time!
      $.ajaxSetup({
        timeout: 0
      });

      $.getJSON(campaignUrl, function () {
        alert('Your campaign has been transfered successfully to a background process. All the emails should be sent in the next 10-15 minutes. After emails are sent, more info can be found in Sendgrid.');
        $('.grey-overlay').remove();

        spinner.stop();
      });
    }
  });
});
