import $ from 'jquery';
import compToPriorFiscalYear701Graph from '@/charts/section_701_attainment_comparison';
import section701SummaryGraph from '@/charts/section_701_attainment_overall';

$(function () {
  const ChartOptions = window.ChartOptions;

  if (ChartOptions.section701SummaryUrl && ChartOptions.section701PreviousFySummaryUrl) {
    $.when(
      $.getJSON(ChartOptions.section701SummaryUrl),
      $.getJSON(ChartOptions.section701PreviousFySummaryUrl)
    ).done(function(currentFyData, previousFyData) {
      section701SummaryGraph(currentFyData[0].result);
      compToPriorFiscalYear701Graph(currentFyData[0], previousFyData[0]);
    }).fail(function(err) {
      console.error('ajax error: ', err);
    });
  }
});
