import $ from 'jquery';
import Highcharts from '@/lib/highcharts_with_modules';

export default function compToPriorFiscalYear701Graph (currentFyData, previousFyData) {
  var currentFySeries = [
    currentFyData.result.alt_gges_total,
    currentFyData.result.missed_opp_701_gges,
  ];

  var previousFySeries = [
    previousFyData.result.alt_gges_total,
    previousFyData.result.missed_opp_701_gges,
  ];

  Highcharts.chart("section701AttainmentComparisonGraph", {
    chart: {
      type: "column"
    },
    title: { 
      text: "Comparison to Prior Fiscal Year" 
    }, 
    xAxis: {
      categories: ["FleetDASH 701 Attainment (GGEs)", "Covered Missed Opportunities (GGEs)"]
    },
    yAxis: {
      title: {
        text: "GGE"
      }
    },
    tooltip: {
      useHTML: true,
      formatter: function() {
        return "<b>"+ this.x + ": </b> "+ Math.round(this.y).toLocaleString() +" GGE";
      }
    },
    plotOptions: { 
      column: { 
        // allowPointSelect: true, 
        cursor: "pointer", 
        showInLegend: true,
        dataLabels: {
          enabled: true,
          useHTML: true,
          formatter: function () {
            var lastYear = previousFySeries[this.point.x],
            y = this.y,
            val;

            // the reduce makes sure that there's actually a comparison worth doing
            if (previousFySeries.reduce(function(a,b){return a+b;}) <= 1000) {
              $("#section-701-no-prior-comparison").html("Prior year data is incomplete.");
            } else if (lastYear !== y && previousFySeries.reduce(function(a,b){return a+b;}) !== 0) {
              val = Math.round((((y-lastYear)/lastYear)*100));
              switch (this.point.x) {
                case 0:
                  if (val > 0) {
                    return "<span style='color:green'>+" + val + "%</span>";
                  } else {
                    return "<span style='color:red'>" + val + "%</span>";
                  }
                case 1:
                  if (val < 0) {
                    return "<span style='color:green'>" + val + "%</span>";
                  } else {
                    return "<span style='color:red'>+" + val + "%</span>";
                  }
              }
            }
          }
        }
      } 
    }, 
    series: [ 
      { 
        name: 'FY ' + (parseInt(previousFyData.inputs.fp_start_date.substring(6), 10) + 1),
        data: previousFySeries,
        color: "#C3C8C8"
      }, 
      {
        name: 'FY ' + (parseInt(currentFyData.inputs.fp_start_date.substring(6), 10) + 1),
        data: currentFySeries,
        color: "#5E6A71"
      }
    ], 
    legend: { 
      enabled: true,
      align: "right",
      verticalAlign: "middle",
      layout: "vertical"
    }, 
    credits: { 
      enabled: false 
    }, 
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG', 'separator', 'downloadCSV', 'downloadXLS']
        }
      },
    } 
  });
}
