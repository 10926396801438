import $ from 'jquery';
import DataTable from 'datatables.net-bs';
import Highcharts from '@/lib/highcharts_with_modules';

function UpdateQueryString(key, value, url) {
  if (!url) url = window.location.href;
    var re = new RegExp('([?&])' + key + '=.*?(&|#|$)(.*)', 'gi');
    var hash = [];

  if (re.test(url)) {
    if (typeof value !== 'undefined' && value !== null)
    return url.replace(re, '$1' + key + '=' + value + '$2$3');
  else {
    hash = url.split('#');
    url = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
    if (typeof hash[1] !== 'undefined' && hash[1] !== null) 
    url += '#' + hash[1];
    return url;
    }
  } else {
  if (typeof value !== 'undefined' && value !== null) {
    var separator = url.indexOf('?') !== -1 ? '&' : '?';
    hash = url.split('#');
    url = hash[0] + separator + key + '=' + value;
    if (typeof hash[1] !== 'undefined' && hash[1] !== null) 
    url += '#' + hash[1];
    return url;
    }
    else
    return url;
  }
}

// used in the tables as well to persist the tab param across controllers
window.getQueryString = function getQueryString() {
  var result = {}, queryString = location.search.slice(1),
  re = /([^&=]+)=([^&]*)/g, m;

  // eslint-disable-next-line no-cond-assign
  while (m = re.exec(queryString)) {
    result[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
  }

  return result;
}

// this fires every time we change tabs when the new tab is showing
$('a[data-toggle="tab"]').on('shown.bs.tab', function () {
  // this reflows the charts to fit in their containers.
  // seems to be an issue for charts that weren't showing when the page loaded
  Highcharts.charts.forEach(function (e) {
    e.reflow();
  });

  var url = '';
  var activeTab = $('.tab-pane.active').attr('id');
  if (activeTab == 'fuelUseTab') {
    url = UpdateQueryString('tab', 'fuelUse');
  } else if (activeTab == 'mandateTab') {
    url = UpdateQueryString('tab', 'mandate');
  } else if (activeTab == 'ghgTab') {
    url = UpdateQueryString('tab', 'ghg');
  } else if (activeTab == 'seven01Tab') {
    url = UpdateQueryString('tab', 'seven01');
  } else if (activeTab == 'vatTab') {
    url = UpdateQueryString('tab', 'vat');
  } else if (activeTab == 'zevTab') {
    url = UpdateQueryString('tab', 'zev');
  } else {
    url = UpdateQueryString('tab', 'inventory');
  }

  var tables = DataTable.tables({ visible: true, api: true });
  tables.columns.adjust();

  // because ie8 :(
  if (!!window.history && !!window.history.pushState) {
    window.history.pushState({tab:activeTab}, activeTab, url);

    tables.ajax.reload(); // TODO is reloading the best option?
  } else {
    window.location.href = url;
  }
});

// change the url and table links when back button is used
$(window).on('popstate', function () {
  const getQueryString = window.getQueryString;

  var currentTab = getQueryString().tab;
  if (currentTab == 'inventory') {
    $('a[href="#inventoryTab"]').click();
  } else if (currentTab == 'mandate') {
    $('a[href="#mandateTab"]').click();
  } else if (currentTab == 'ghg') {
    $('a[href="#ghgTab"]').click();
  } else if (currentTab == 'seven01') {
    $('a[href="#seven01Tab"]').click();
  } else if (currentTab == 'vat') {
    $('a[href="#vatTab"]').click();
  } else if (currentTab == 'fuelUse') {
    $('a[href="#fuelUseTab"]').click();
  } else {
    $('a[href="#zevTab"]').click();
  }

  var tables = DataTable.tables({ visible: true, api: true });
  tables.columns.adjust();

  // safari, you make me soo sad :(
  if (currentTab !== undefined && window.location.pathname.match(/admin/) === null) {
    tables.ajax.reload();
  }
});
