import $ from 'jquery';
import DataTable from 'datatables.net-bs';

$(function () {
  const TableOptions = window.TableOptions;
  const formatUrlDate = window.formatUrlDate;
  const getQueryString = window.getQueryString;

  // for the children table
  new DataTable('#children_701', {
    // serverSide: true,
    searching: true,
    ordering: true,
    order: [6, 'desc'],
    'columnDefs': [
      { className: 'justify-right', 'targets': [1, 2, 3, 4, 5, 6, 7] }
    ],
    'ajax': function (data, callback) {
      $.ajax({
        url: TableOptions.section701NodesUrl,
        data: {
          // limit: data.length,
          // page: data.start/10 + 1,
          fp_start_date: formatUrlDate(TableOptions.fp_start_date),
          fp_end_date: formatUrlDate(TableOptions.fp_end_date)
        },
        success: function (json) {
          var dataArr = [];
          for ( var i=0; i<json.result.length; i++ ) {
            if (TableOptions.executiveOrgLevel && TableOptions.organizationId == json.result[i].org_id) {
              dataArr.push(json.result[i]);
            } else if (TableOptions.executiveOrgLevel === undefined) {
              dataArr.push(json.result[i]);
            }
          }

          var o = {
            recordsTotal: json.metadata.resultset.count,
            recordsFiltered: json.metadata.resultset.count,
            data: dataArr
          };

          callback(o);
        }
      });
    },
    'columns': [
      {'data': function (row) {
        var name;
        if (row.node_alt_name === null || row.node_alt_name === '') {
          name = row.node_name;
        } else if (row.org_name !== undefined) {
          name = row.org_name;
        } else {
          name = row.node_alt_name;
        }

        var treeString = '';
        if (TableOptions.nodeId) {
          if (typeof TableOptions.nodeTree !== 'undefined') {
            TableOptions.nodeTree.forEach(function (node) {
              treeString += '&node_tree[]=' + node;
            });
            treeString += '&node_tree[]=' + TableOptions.nodeId;
          } else {
            treeString = '&node_tree[]=' + TableOptions.nodeId;
          }
        }

        if (TableOptions.executiveTopLevel) {
          return '<a href="/FleetDASH/executive/' + row.org_id + '">' + name + '</a>';
        } else if (TableOptions.executiveOrgLevel) {
          return name;
        } else {
          return '<a href="/FleetDASH/nodes/' + row.node_id + '?organization_id=' + TableOptions.organizationId + '&hierarchy_id=' + TableOptions.hierarchyId + '&tab=' + getQueryString().tab  + treeString + '">' + name + '</a>';
        }
      }},
      {'data': function (row) {
        return row.covered_vehicles_count;
      }},
      {'data': function (row) {
        return row.exempt_vehicles_count;
      }},
      {'data': function (row) {
        return Math.round(row.alt_gges).toLocaleString();
      }},
      {'data': function (row) {
        return Math.round(row.alt_gges_covered).toLocaleString();
      }},
      {'data': function (row) {
        return Math.round(row.non_alt_missed_opp_gges).toLocaleString();
      }},
      {'data': function (row) {
        return Math.round(row.non_alt_missed_opp_gges_covered).toLocaleString();
      }},
      {'data': function (row) {
        return row.attainment_701;
      }}
    ]
  });
});
