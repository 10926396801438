import $ from 'jquery';
import DataTable from 'datatables.net-bs';

$(function() {
  const TableOptions = window.TableOptions;

  var createdCellHighlightMax = function(td, cellData, rowData) {
    var roundGges = [
      rowData.ev_gges_reduced,
      rowData.e85_gges_reduced,
      rowData.lpg_gges_reduced,
      rowData.cng_gges_reduced,
      rowData.b20_gges_reduced,
    ].map(function(f) { return Math.round(f) })
    var greatestReduction = roundGges.reduce(function(acc, cur) {
      return Math.max(acc, cur);
    });
    if (greatestReduction && greatestReduction > 0 && Math.round(cellData) === greatestReduction) {
      $(td).addClass('vat-highlight-shading');
    };
  }

  var defaultContent = 'N/A';
  var columns = [
    {
      name: 'vin',
      data: 'vin',
      render: function(data, type, row) {
        return '<a href="/FleetDASH/vehicles/' + row.vehicle_id + '/vat?organization_id=' + TableOptions.organizationId + '&hierarchy_id=' + TableOptions.hierarchyId + '">' + data + '</a>';
      },
      orderable: false,
    },
    {
      name: 'internal_id',
      data: 'internal_id',
      orderable: false,
    },
    {
      name: 'vehicle_segment',
      data: 'vehicle_segment',
      orderable: false,
    },
    {
      name: 'fleetdash_fuel_type',
      data: 'fleetdash_fuel_type',
      orderable: false,
    },
    {
      name: 'total_gges',
      data: 'total_gges',
      orderable: true,
      className: 'justify-right',
      defaultContent: defaultContent,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'ev_gges_reduced',
      data: 'ev_gges_reduced',
      orderable: true,
      className: 'justify-right',
      defaultContent: defaultContent,
      render: DataTable.render.number(',', '.', 0),
      createdCell: createdCellHighlightMax,
    },
    {
      name: 'e85_gges_reduced',
      data: 'e85_gges_reduced',
      orderable: true,
      className: 'justify-right',
      defaultContent: defaultContent,
      render: DataTable.render.number(',', '.', 0),
      createdCell: createdCellHighlightMax,
    },
    {
      name: 'b20_gges_reduced',
      data: 'b20_gges_reduced',
      orderable: true,
      className: 'justify-right',
      defaultContent: defaultContent,
      render: DataTable.render.number(',', '.', 0),
      createdCell: createdCellHighlightMax,
    },
    {
      name: 'cng_gges_reduced',
      data: 'cng_gges_reduced',
      orderable: true,
      className: 'justify-right',
      defaultContent: defaultContent,
      render: DataTable.render.number(',', '.', 0),
      createdCell: createdCellHighlightMax,
    },
    {
      name: 'lpg_gges_reduced',
      data: 'lpg_gges_reduced',
      orderable: true,
      className: 'justify-right',
      defaultContent: defaultContent,
      render: DataTable.render.number(',', '.', 0),
      createdCell: createdCellHighlightMax,
    },
  ];
  var columnIndexByName = columns.reduce(function(byName, col, index) { byName[col.name] = index; return byName }, {});

  new DataTable('#vat_hd_summary_table', {
    serverSide: true,
    searching: true,
    ordering: true,
    order: [[columnIndexByName.ev_gges_reduced, 'desc']],
    ajax: function (data, callback) {
      var order_column = data.order[0].column;
      var sort_by = columns[order_column].name;

      $.ajax({
        url: TableOptions.vatHdSummaryUrl,
        data: {
          fiscal_year: TableOptions.vatFiscalYear,
          limit: data.length,
          page: data.start/10 + 1,
          sort_order: data.order[0].dir,
          sort_by: sort_by,
          search: data.search.value,
        },
        success: function (json) {
          var jsonData = [];
          if (json.result) {
            for (var i = 0; i < json.result.length; i++) {
              jsonData.push(json.result[i]);
            }
          }

          var o = {
            recordsTotal: json.metadata.resultset.count,
            recordsFiltered: json.metadata.resultset.count,
            data: jsonData
          };
          callback(o);
        }
      });
    },
    columns: columns,
  });
});
