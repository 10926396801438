import $ from 'jquery';
import DataTable from 'datatables.net-bs';

$(function() {
  const TableOptions = window.TableOptions;

  var defaultContent = 'N/A';
  var columns = [
    {
      name: 'node_name',
      data: function (row) {
        var name;
        if (row.vehicle_class === "T") {
          name = "Total";
        } else if (row.vehicle_class === "LD") {
          name = "Light-Duty";
        } else if (row.vehicle_class === "HD") {
          name = "Medium and Heavy-Duty";
        }

        return name;
      },
      orderable: false,
      className: 'heavy-border-right',
    },
    {
      name: 'total_vehicle_count',
      data: 'total_vehicle_count',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'current_zev_count',
      data: 'current_zev_count',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'zev_candidate_great_count',
      data: 'zev_candidate_great_count',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'zev_candidate_good_count',
      data: 'zev_candidate_good_count',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'zev_candidate_mediocre_count',
      data: 'zev_candidate_mediocre_count',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'zev_candidate_challenging_count',
      data: 'zev_candidate_challenging_count',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
    {
      name: 'zev_candidate_no_option_count',
      data: 'zev_candidate_no_option_count',
      className: 'justify-right',
      defaultContent: defaultContent,
      orderable: false,
      render: DataTable.render.number(',', '.', 0),
    },
  ];
  var columnIndexByName = columns.reduce(function(byName, col, index) { byName[col.name] = index; return byName }, {});

  new DataTable('#zev_node_summary_table', {
    serverSide: true,
    ordering: false,
    paging: false,
    info: false,
    order: [[columnIndexByName.node_name, 'desc']],
    ajax: function (data, callback) {
      $.ajax({
        url: TableOptions.zevNodeSummaryUrl,
        data: {
          fiscal_year: TableOptions.vatFiscalYear,
        },
        success: function (json) {
          var jsonData = [];
          if (json.result) {
            for (var i = 0; i < json.result.length; i++) {
              jsonData.push(json.result[i]);
            }
          }

          var o = {
            recordsTotal: json.metadata.resultset.count,
            recordsFiltered: json.metadata.resultset.count,
            data: jsonData
          };
          callback(o);
        }
      });
    },
    columns: columns,
  });
});
