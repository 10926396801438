import Highcharts from 'highcharts/highstock.src';
import highchartsAccessibilityInit from 'highcharts/modules/accessibility';
import highchartsExportDataInit from 'highcharts/modules/export-data';
import highchartsExportingInit from 'highcharts/modules/exporting';
import highchartsNoDataToDisplayInit from 'highcharts/modules/no-data-to-display.src';
import highchartsOfflineExportingInit from 'highcharts/modules/offline-exporting';

highchartsAccessibilityInit(Highcharts);
highchartsExportingInit(Highcharts);
highchartsExportDataInit(Highcharts);
highchartsOfflineExportingInit(Highcharts);
highchartsNoDataToDisplayInit(Highcharts);

export default Highcharts;
